import { useEffect, useState } from "react";
import Backend from "../../presets/backend";
import axios from "axios";

type UseFireDocProps = {
  id: string;
  initialize?: boolean;
};
const useProduct = ({ id, initialize = false }: UseFireDocProps) => {
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState({} as any);

  useEffect(() => {
    if (initialize) {
      runQuery(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const runQuery = async (id: any) => {
    setLoading(true);
    try {
      const url = Backend.products + "/" + id;
      const res = await axios.get(url).then((res) => res.data);

      if (res.code !== "success") {
        throw new Error(res.message);
      }
      setProduct(res.data);

    } catch (error) {
      console.error(error);
    } finally {
    setLoading(false);
    }
  };

  return {
    loading,
    product,
    setProduct,
    runQuery,
  };
};


const useProducts = ({
  initialize = false,
  params = {},
}) => {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([] as any);
  const [response, setResponse] = useState({} as any);
  const [pagination, setPagination] = useState({} as any);

  useEffect(() => {
    if (initialize) {
      runQuery(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const runQuery = async (params: any) => {
    setLoading(true);
    try {
      const url = Backend.products + "?" + new URLSearchParams(params).toString();
      const res = await axios.get(url).then((res) => res.data);

      if (res.code !== "success") {
        throw new Error(res.message);
      }
      setProducts(res.data);
      setResponse(res);
      setPagination(res.pagination);

    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    products,
    setProducts,
    response,
    runQuery,
    pagination,
  };
};

export { useProduct, useProducts };

/* eslint-disable react-hooks/exhaustive-deps */
'use client';

import useDocumentTitle from "../hooks/userDocumentTitle";
import MainTemplate from "./MainTemplate";
import AttributesTable from "../components/AttributesTable";
import { useAxiosGet } from "../hooks/useAxios";
import Backend from "../presets/backend";

import { collection, getDocs, query } from "firebase/firestore";
import { db } from "..";
import { toast } from "react-toastify";



const Attributes = () => {
    useDocumentTitle('Attributes');

      //get attributes
  const {
    data: attributes,
    loading,
    ...attributesRest
  } = useAxiosGet({
    url: Backend.attributes,
    defaultData: [],
  });

    const onTableUpdated = (attribute: any) => {
        attributesRest.run();
    }

    const exportFirebaseToJson = async () => {
      const loading = toast.loading("Exporting docs to JSON file...");
  
      try {
        let docs: any[] = [];
        const docsRef = collection(db, "attributes");
        const q = query(docsRef);
  
        await getDocs(q).then((snapshot) => {
          snapshot.forEach((doc) => {
            docs.push({ ...doc.data(), id: doc.id });
          });
        });
  
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
          JSON.stringify(docs)
        )}`;
        const link = document.createElement("a");
        link.href = jsonString;
        link.download = "dipsy_attributes.json";
  
        link.click();
      } catch (error: any) {
        console.log(error);
        toast.error("Failed to export docs to JSON file");
      } finally {
        toast.dismiss(loading);
      }
    };


    return (
        <>

            <MainTemplate title="Attributes">


                {/* <!-- Attributes --> */}
                <AttributesTable attributes={attributes} onTableUpdated={onTableUpdated} loading={loading} />

                {/* export button */}
      <div className="xflex flex-row w-full items-center justify-center hidden">
        <button
          onClick={exportFirebaseToJson}
          type="button"
          className="p-2.5 text-sm font-medium text-white bg-black rounded-lg border border-black hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-black dark:hover:bg-gray-900 dark:focus:ring-blue-800"
        >
          Export Docs to JSON
        </button>
      </div>

                <br />
                <br />
                <br />
                <br />



            </MainTemplate>

        </>
    );
}

export default Attributes;
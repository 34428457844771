/* eslint-disable eqeqeq */
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useProduct } from "../hooks/useProducts";
import Badge, { BadgeProps } from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import {
  Add,
  ArrowBackIos,
  Remove,
  ShoppingCartOutlined,
  Star,
} from "@mui/icons-material";
import { helpers } from "../../helpers";
import { useState } from "react";
import { Button } from "@mui/material";
import { Shimmer } from "react-shimmer";
import { useCartContext } from "../hooks/useCart";
import { useSmartLoadingDialogContext } from "../components/SmartLoadingDialog";
import { toast } from "react-toastify";
import AttributeCard from "../components/AttributeCard";
import ProductPageImageSlider from "../components/ProductPageImageSlider";

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -1,
    top: 5,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

export default function AppProduct() {
  const navigate = useNavigate();

  const cartContext = useCartContext();

  const smartLoading = useSmartLoadingDialogContext();

  const { id } = useParams();

  const [quantity, setQuantity] = useState(1);
  const [useNegotiatedPrice, setUseNegotiatedPrice] = useState(false);
  const [addNote, setAddNote] = useState(false);
  const [negotiatedPrice, setNegotiatedPrice] = useState("");
  const [note, setNote] = useState(null as any);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedAttributes, setSelectedAttributes] = useState({} as any);
  const [showAddedToCartDialog, setShowAddedToCartDialog] = useState(false);

  const { product, loading } = useProduct({ id: id!, initialize: true });

  const openCart = () => {
    navigate("/app/cart");
  };

  const addToCart = async () => {
    // TODO: set attributes
    var buyingPrice: number = product.price;

    if (useNegotiatedPrice) {
      if (negotiatedPrice == "") {
        toast.error("Please enter a negotiated price");
        return;
      }
      buyingPrice = parseFloat(negotiatedPrice) / quantity;
    }

    smartLoading.show();

    try {
      
      const res: any = await cartContext.addToCart(
        product._id,
        quantity,
        buyingPrice,
        selectedAttributes,
        note,
      );
      
      if (res?.code !== "success") {
        // throw new Error(res?.message || "An error occured"); //error already handled in useCart.tsx
        return;
      }
      toast.success("Added to cart");
      setShowAddedToCartDialog(true);
    } catch (error: any) {
      toast.error(error?.message || "An error occured");
    } finally {
      smartLoading.dismiss();
    }

 
  };

  return (
    <>
      {/* header */}
      <header className="z-30 absolute top-0 left-0 w-full py-4 sm:shadow">
        <div className="container flex items-center justify-between h-full px-5 mx-auto">
          {/* back button */}
          <ul className="flex items-center flex-shrink-0 space-x-6">
            <li className="relative">
              <IconButton
                aria-label="cart"
                color="primary"
                className="!bg-white"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ArrowBackIos className="ml-2 my-1" />
              </IconButton>
            </li>
          </ul>
          <ul className="flex items-center flex-shrink-0 space-x-6">
            <li className="relative">
              <IconButton
                aria-label="cart"
                color="primary"
                className="!bg-white"
                onClick={() => openCart()}
              >
                <StyledBadge
                  badgeContent={cartContext.cart.items?.length || 0}
                  color="error"
                >
                  <ShoppingCartOutlined className="mx-1 my-1" />
                </StyledBadge>
              </IconButton>
            </li>
          </ul>
        </div>
      </header>

      {/* page */}
      {loading ? (
        <PageShimmer />
      ) : (
        //one column mobile, 2 column desktop
        <div className="sm:grid sm:grid-cols-2 sm:pt-[90px] sm:px-10 sm:gap-x-5">
          {/* images */}
          <ProductPageImageSlider product={product} />
          {/* details */}
          <div className="bg-white px-4 py-3 space-y-5">
            {/* product name */}
            <div className="text-black font-bold text-2xl leading-tight w-full">
              {product?.name}
            </div>
            {/* rating */}
            <div className="flex items-center gap-1">
              <div className="text-black text-xs font-light leading-3 w-full">
                {(product?.rating || 0) == 0 ? "N/A" : product.rating}{" "}
                <Star className="!text-yellow-400" />
              </div>
            </div>
            {/* description */}
            <div
              className="text-gray-500 text-lg leading-6 w-full"
              dangerouslySetInnerHTML={{
                __html: product?.description?.replace(
                  /(?:\r\n|\r|\n)/g,
                  "<br>"
                ),
              }}
            ></div>
            {/* attributes */}
            {
              product.has_attributes && (
                <div className="flex flex-col gap-2">
                  <div className="text-black text-sm font-bold leading-3 w-full">
                    Select Options
                  </div>
                  <div className="flex flex-col w-full gap-y-2 p-3 bg-gray-100 rounded-lg">
                    { 
                      product.attributes.map((attribute: any, index: number) => {
                      return <AttributeCard 
                        key={`${attribute.name + Math.random()}`}
                        attribute={attribute}
                        selectedAttributes={selectedAttributes}
                        onOptionChange={(attr, option) => {
                          setSelectedAttributes({ ...selectedAttributes, [attr.name]: option.value });
                        }}
                        />
                    })
                  }
                  </div>
                </div>
              )
            }
            {/* in stcok */}
            <div>
              {product?.quantity > 0 ? (
                <div className="text-black text-xl font-bold leading-3 w-full text-center">
                  {product?.quantity} in Stock
                </div>
              ) : (
                <div className="text-red-700 text-xl font-bold leading-3 w-full text-center">
                  Out of stock
                </div>
              )}
            </div>
            {/* quantity */}
            <div className="flex flex-col gap-2">
              <div className="text-black text-sm font-bold leading-3 w-full">
                Quantity
              </div>
              <div className="flex flex-row items-center justify-center gap-5">
                {/* minus */}
                <IconButton
                  aria-label="delete"
                  className="!bg-gray-700"
                  onClick={() => {
                    if (quantity > 1) {
                      setQuantity(quantity - 1);
                    }
                  }}
                >
                  <Remove className="!text-white" />
                </IconButton>
                {/* input */}
                <div className="text-black text-2xl font-bold leading-3">
                  {quantity}
                </div>
                {/* plus */}
                <IconButton
                  aria-label="delete"
                  className="!bg-gray-700"
                  onClick={() => setQuantity(quantity + 1)}
                >
                  <Add className="!text-white" />
                </IconButton>
              </div>
            </div>
            {/* price */}
            <div className="flex flex-col gap-2 items-center justify-center">
              <div className="text-black text-sm font-bold leading-3 w-full">
                Retailer Price
              </div>
              <div className="bg-white text-black border border-black rounded-lg text-2xl font-bold py-1 px-3">
                N{helpers.money(product.price)}
              </div>
              <div
                className={`bg-white text-black border border-black rounded-lg text-2xl font-bold py-1 px-3 ${
                  quantity < 2 && "hidden"
                }`}
              >
                <span className="text-gray-500">{quantity}x =</span> N
                {helpers.money(product.price * quantity)}
              </div>
              {/* negotiation switch */}
              <Button
                variant="text"
                className="!normal-case !underline !text-lg !text-gray-600"
                onClick={() => setUseNegotiatedPrice(!useNegotiatedPrice)}
              >
                {useNegotiatedPrice
                  ? "Use retailer price"
                  : "Add client negotiated price"}
              </Button>
              {/* negotiation price */}
              <input
                type="number"
                className={`!border !border-gray-400 !rounded-lg !text-gray-600 !px-3 !py-3 !w-full text-center text-2xl font-bold placeholder:font-normal ${
                  !useNegotiatedPrice && "hidden"
                }`}
                placeholder="Enter price"
                value={negotiatedPrice}
                onChange={(e) => setNegotiatedPrice(e.target.value)}
              />
              {/* add note button */}
              <Button
                variant="text"
                className="!normal-case !underline !text-lg !text-gray-600"
                onClick={() => {
                  setAddNote(!addNote);
                  setNote(null);
                }}
              >
                {addNote
                  ? "Remove note"
                  : "Add note"}
              </Button>
              {/* note */}
              <textarea
                className={`!border !border-gray-400 !rounded-lg !text-gray-600 !px-3 !py-3 !w-full text-sm font-bold placeholder:font-normal ${
                  !addNote && "hidden"
                }`}
                placeholder="Enter note"
                value={note || ""}
                onChange={(e) => setNote(e.target.value)}
              ></textarea>
              {/* add to cart button */}
              <Button
                variant="contained"
                size="small"
                color="primary"
                className="!px-6 !rounded-xl !normal-case !text-xl !w-full !h-[60px] !mt-2"
                onClick={() => {
                  addToCart();
                }}
              >
                Add to Cart
              </Button>
              <br/>
              <br/>
            </div>
          </div>
        </div>
      )}
      <AddedToCartDialog show={showAddedToCartDialog} setShow={setShowAddedToCartDialog} 
        item={{ 
          ...product, 
          quantity,
          subtotal: useNegotiatedPrice ? parseFloat(negotiatedPrice) : product.price * quantity
          }} />
    </>
  );
}

type AddedToCartDialogProps = {
  show: boolean;
  setShow: (show: boolean) => void;
  item: any;
  onContinueShopping?: () => void;
};
export const AddedToCartDialog = ({ show, item, setShow, onContinueShopping }: AddedToCartDialogProps) => {
    const navigate = useNavigate();
  const openCart = () => {
    navigate("/app/cart");
  };
  return (
    <>
      <div
        className={`fixed left-0 top-0 h-screen z-50 bg-[#000000c0] w-full flex items-center justify-center ${
          !show && "hidden"
        }`}
      >
        <div className="flex flex-col justify-center items-center gap-y-3 text-sm text-gray-700 bg-white rounded-xl p-5 w-full sm:w-auto mx-5 sm:mx-auto">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-[80px] h-[80px] text-green-700"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>

          {/* product name */}
          <div className="text-black font-bold text-xl leading-tight w-full">
            {item?.name}
          </div>
          {/* quantity */}
          <div className="text-black font-normal text-lg leading-tight w-full">
            Quantity: {item?.quantity}
          </div>
          {/* subtotal */}
          <div className="text-black font-normal text-lg leading-tight w-full">
            Subtotal: N{helpers.money(item?.subtotal)}
          </div>

          <Button
            variant="contained"
            size="small"
            color="primary"
            className="!px-6 !rounded-xl !normal-case !text-lg !w-full !h-[60px] !mt-2"
            onClick={() => {
              openCart();
            }}
          >
            CHECKOUT
          </Button>
          <Button
            variant="contained"
            size="small"
            color="secondary"
            className="!px-6 !bg-white !rounded-xl !normal-case !text-lg !w-full !h-[60px] !mt-2"
            onClick={() => {
                if (onContinueShopping) {
                    onContinueShopping();
                    return
                }
                setShow(false);
            }}
          >
            CONTINUE SHOPPING
          </Button>
        </div>
      </div>
    </>
  );
};

const PageShimmer = () => {
  return (
    <div className="sm:grid sm:grid-cols-2 sm:pt-[90px] sm:px-10 sm:gap-x-5">
      {/* images */}
      <div className="bg-gray-400 h-[350px] sm:h-[500px] rounded-bl-3xl rounded-br-3xl sm:rounded-3xl relative overflow-clip">
        <Shimmer height={350} width={500} className="!w-full sm:!h-[500px]" />
      </div>
      {/* details */}
      <div className="bg-white px-4 py-3 space-y-5">
        {/* product name */}
        <Shimmer height={30} width={300} className="!w-full" />
        {/* rating */}
        <Shimmer height={20} width={100} className="!w-[100px]" />
        {/* description */}
        <Shimmer height={100} width={500} className="!w-full" />
        {/* in stcok */}
        <Shimmer height={30} width={300} className="!w-[200px]" />
        {/* quantity */}
        <Shimmer height={30} width={300} className="!w-full" />
        {/* price */}
        <Shimmer height={30} width={300} className="!w-full" />
        {/* negotiation switch */}
        <Shimmer height={30} width={300} className="!w-full" />
        {/* negotiation price */}
        <Shimmer height={30} width={300} className="!w-full" />
        {/* add to cart button */}
        <Shimmer height={60} width={300} className="!w-full" />
      </div>
    </div>
  );
};

import { useEffect, useState } from "react";
import SkyeModal from "../../components/SkyeModal";
import { toast } from "react-toastify";
import { TagsInput } from "react-tag-input-component";
import axios from "axios";
import Backend from "../../presets/backend";

type Props = {
  title?: string;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  attribute?: any;
  setAttribute: (attribute: any) => void;
  onModalUpdated: (attribute: any) => void;
};
const AttributeModal = ({
  title = "New Attribute",
  showModal,
  setShowModal,
  attribute,
  setAttribute,
  onModalUpdated,
}: Props) => {
  const [attributeData, setAttributeData] = useState(attribute ?? {});
  const [options, setOptions] = useState([] as string[]);

  useEffect(() => {
    setAttributeData(attribute);
    if (attribute.options) {
      setOptions(attribute.options.map((option: any) => option.label) ?? []);
    }
  }, [attribute, attributeData.options]);

  const onInputChanged = (e: any) => {
    setAttributeData({ ...attributeData, [e.target.name]: e.target.value });
  };

  const onFormSubmit = async (e: any) => {
    e.preventDefault();

    const loading = toast.loading("Please wait...");

    try {
      let formData: any = { ...attributeData };
      formData.options = options.map((option) => ({
        label: option,
        value: option,
      }));

      if (formData._id) {
        //update
        delete formData._id;
        delete formData.created_at;
        delete formData.updated_at;
        delete formData.slug;
        delete formData.doc_id;
        delete formData.__v;

        const res = await axios.post(`${Backend.admin_attributes}/${attributeData._id}`, formData).then((res) => res.data);

        if (res.code !== "success") {
          throw new Error(res.message || "An error occurred. Please try again.");
        }

        setAttribute(res.data);
        setAttributeData(res.data);
        onModalUpdated(res.data);
        toast.success(res.message || "Attribute updated successfully.");

      } else {
        //add
        const res = await axios.post(Backend.admin_attributes, formData).then((res) => res.data);

        if (res.code !== "success") {
          throw new Error(res.message || "An error occurred. Please try again.");
        }

        setAttribute(res.data);
        setAttributeData(res.data);
        onModalUpdated(res.data);
        toast.success(res.message || "Attribute added successfully.");

      }

      setShowModal(false);
    } catch (error: any) {
      console.log(error);
      toast.error(error.message || "An error occurred. Please try again.");
    } finally {
      toast.dismiss(loading);
    }
  };

  return (
    <>
      <SkyeModal
        title={attributeData._id ? "Attribute" : "New Attribute"}
        size="max-w-sm"
        flex={false}
        showModal={showModal}
        setShowModal={setShowModal}
      >
        <form
          autoComplete="off"
          className="space-y-6"
          id="attribute-edit-form"
          onSubmit={onFormSubmit}
          method="POST"
          encType="multipart/form-data"
        >
          {/*body*/}
          <div className="relative px-6 pt-6 pb-2 flex-auto">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Name
              </label>
              <input
                name="name"
                value={attributeData.name || ""}
                onChange={onInputChanged}
                type="text"
                id="name"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                placeholder="Name"
                required
              />
            </div>
            <div className="mt-4">
              <label
                htmlFor="options"
                className="block text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Options
              </label>
              <TagsInput
                name="options"
                value={options}
                onChange={(tags) => setOptions(tags)}
                separators={[",", "Enter"]}
                // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                placeHolder="Enter options"
                // required
              />
              <em>press enter or comma to add new tag</em>
            </div>
          </div>
          {/*footer*/}
          <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
            <button
              type="submit"
              className="w-full text-white bg-black hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-gray-700 dark:focus:ring-black"
            >
              <span id="submit-text">
                {attributeData._id ? "Update" : "Add"}
              </span>
            </button>
          </div>
        </form>
      </SkyeModal>
    </>
  );
};

export default AttributeModal;

import axios from "axios";
import { useState } from "react";
import { useCookies } from "react-cookie";

const useToken = () => {
  const [cookies, setCookie] = useCookies(['user'] as any);
  const [token, setToken] = useState(cookies.token);

  axios.defaults.headers.common = {'Authorization': `Bearer ${token}`} //global token (for axios)
  //set global axios header as application/json
  axios.defaults.headers.post['Content-Type'] = 'application/json';


  return {
    token, 
    login(token: any) {
      setCookie('token', token, { path: '/' });
      setToken(token);
    }, 
    logout() {
      document.cookie.split(";").forEach((c) => {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
      setToken(null);
    }
  };
}

export default useToken;





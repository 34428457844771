/* eslint-disable eqeqeq */
import { Button } from "@mui/material";
import { useEffect, useState,  } from "react";


type AttributeCardProps = {
    attribute: any,
    selectedAttributes: any, //{} it is an object
    onOptionChange: (attribute: any, option: any) => void,
};

export default function AttributeCard({ attribute, selectedAttributes, onOptionChange }: AttributeCardProps) {

    const [showCustomOption, setShowCustomOption] = useState(selectedAttributes[attribute.name] != undefined && !attribute?.options?.some((opt: any) => opt.value == selectedAttributes[attribute.name]));
    const [customOptionValue, setCustomOptionValue] = useState((selectedAttributes[attribute.name] || null) as any);
    

    useEffect(() => {

        if (attribute.options.length < 1) {
            setShowCustomOption(true);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    

    return (
        <div className="flex flex-col items-start gap-y-2">
            <div className="font-bold">{attribute.name}</div>
            <div className="flex-wrap space-y-2 space-x-2">
                {   
                    attribute.options.map((option: any, index: number) => {
                        return <Button
                                key={index}
                                className={`!normal-case !rounded-lg 
                                    ${selectedAttributes[attribute.name] == option.value
                                        ? "!bg-black !text-white" : "!bg-white"
                                    }`}
                                onClick={() => {
                                    onOptionChange(attribute, option);
                                }}
                                >
                                {option.label}
                                </Button>
                    })
                }
            </div>
              {/* custom option */}
              <div className={`flex flex-row gap-x-2 w-full ${ 
                (
                    showCustomOption
                )
                ? "" :
                 "hidden"}`}>
                <input
                type="text"
                    className={`!border !border-gray-400 !rounded-lg !text-gray-600 !px-3 !py-3 !w-full text-sm font-bold placeholder:font-normal`}
                    placeholder="Enter custom option"
                    value={customOptionValue || ""}
                    autoFocus={true}
                    onChange={(e) => {
                        if ((e.target.value || "").length > -1) {
                            setCustomOptionValue(e.target.value);
                            onOptionChange(attribute, { label: e.target.value, value: e.target.value });
                        }
                    }}
                />
                {/* <Button
                    className="!normal-case !text-sm !text-white !bg-black"
                    onClick={() => {
                        onOptionChange(attribute, { label: customOptionValue, value: customOptionValue });
                    }}
                >
                    Add
                </Button> */}
              </div>
              {/* add custom button */}
            <Button
                variant="text"
                className="!normal-case !underline !text-sm !text-gray-600"
                onClick={() => {
                    setShowCustomOption(!showCustomOption);
                }}
              >
                {showCustomOption
                  ? "Cancel"
                  : "Add custom option"}
              </Button>
        </div>
    );
};

import { useEffect, useState } from "react";
import SkyeModal from "../../components/SkyeModal";
import { toast } from "react-toastify";
import axios from "axios";
import Backend from "../../presets/backend";

type Props = {
  title?: string;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  notification?: any;
  setNotification: (notification: any) => void;
  onModalUpdated: (notification: any) => void;
};
const NotificationModal = ({
  showModal,
  setShowModal,
  notification,
  setNotification,
  onModalUpdated,
}: Props) => {
  const [notificationData, setNotificationData] = useState(notification ?? {});

  useEffect(() => {
    setNotificationData(notification);
  }, [notification]);

  const onInputChanged = (e: any) => {
    setNotificationData({
      ...notificationData,
      [e.target.name]: e.target.value,
    });
  };

  const onFormSubmit = async (e: any) => {
    e.preventDefault();

    const loading = toast.loading("Please wait...");

    let formData: any = { ...notificationData };

    try {
      const topic = "general";

      if (formData._id) {
        //update
        //remove id, created_at
        delete formData._id;
        delete formData.created_at;
        delete formData.updated_at;
        delete formData.__v;

        //save to server
        const res = await axios
          .post(`${Backend.admin_notifications}/${notificationData._id}`, {
            ...formData,
            topic,
          })
          .then((res) => res.data);

        if (res.code !== "success") {
          throw new Error(res.message);
        }

        setNotificationData(res.data);
        setNotificationData(res.data);
        onModalUpdated(res.data);
        toast.success(res.message);
      } else {
        //create

        const res = await axios
          .post(Backend.admin_notifications, {
            ...formData,
            topic,
          })
          .then((res) => res.data);

        if (res.code !== "success") {
          throw new Error(res.message);
        }

        setNotificationData(res.data);
        setNotificationData(res.data);
        onModalUpdated(res.data);
        toast.success(res.message);
      }
      setShowModal(false);
    } catch (error: any) {
      toast.error(error.message || "An error occurred. Please try again.");
    } finally {
      toast.dismiss(loading);
    }
  };

  return (
    <>
      <SkyeModal
        title={notificationData._id ? "Notification" : "New Notification"}
        size="max-w-sm"
        flex={false}
        showModal={showModal}
        setShowModal={setShowModal}
      >
        <form
          autoComplete="off"
          className="space-y-6"
          id="notification-edit-form"
          onSubmit={onFormSubmit}
          method="POST"
          encType="multipart/form-data"
        >
          {/*body*/}
          <div className="relative p-6 flex-auto">
            <div>
              <label
                htmlFor="title"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Title ( 🥳 You can paste any emoji 🥳 ){" "}
              </label>
              <input
                name="title"
                value={notificationData.title || ""}
                onChange={onInputChanged}
                type="text"
                id="title"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                placeholder="Notification title"
                required
              />
            </div>

            <div>
              <label
                htmlFor="content"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Content{" "}
              </label>
              <textarea
                name="content"
                value={notificationData.content || ""}
                onChange={onInputChanged}
                id="content"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                placeholder="Notification Content"
                required
              ></textarea>
            </div>
          </div>
          {/*footer*/}
          <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
            <button
              type="submit"
              className="w-full text-white bg-black hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-gray-700 dark:focus:ring-black"
            >
              <span id="submit-text">
                {notificationData._id
                  ? "Re-Push Notification"
                  : "Push Notification"}
              </span>
            </button>
          </div>
        </form>
      </SkyeModal>
    </>
  );
};

export default NotificationModal;

/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import CartContext from "../CartContext";
import useToken from "../../hooks/useToken";
import axios from "axios";
import Backend from "../../presets/backend";
import { toast } from "react-toastify";

export const useCart = () => {
  const { token } = useToken();
  

  const [cart, setCart] = useState({}); //this is the user from the server, always reload from the server
  const [loading, setLoading] = useState(true);
  const [totalLoading, setTotalLoading] = useState(true);

  useEffect(() => {
    fetchCart();
  }, []);

  const fetchCart = async () => {
    if (!token) {
      setLoading(false);
      return;
    }
    setLoading(true);
    setTotalLoading(true);

    try {
      const res = await axios.get(Backend.cart).then((res) => res.data);

      if (res.code !== "success") {
        throw new Error(res.message);
      }

      if (res.data) {
        setCart(res.data);
      }
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
      setTotalLoading(false);
    }
  };

  const addToCart: object = async (
    productId: string,
    quantity: number,
    buyingPrice: number,
    selectedAttributes: any,
    note?: string
  ) => {
    setTotalLoading(true);

    try {
      const res = await axios
        .post(Backend.cart + "/add", {
          product_id: productId,
          quantity: quantity,
          buying_price: buyingPrice || undefined,
          selected_attributes: selectedAttributes || undefined,
          note: note || undefined,
        })
        .then((res) => res.data);

      if (res.code !== "success") {
        throw new Error(res.message);
      }

      setCart(res.data);
      setTotalLoading(false);
      return res;
    } catch (error: any) {
      console.log(error);
      toast.error(error.message || "An error occurred");
      return error;
    } finally {
      setTotalLoading(false);
    }
  };

  const removeFromCart: object = async (productId: string) => {
    setTotalLoading(true);

    try {
      const res = await axios
        .post(Backend.cart + "/remove", {
          product_id: productId,
        })
        .then((res) => res.data);

      if (res.code !== "success") {
        throw new Error(res.message);
      }

      setCart(res.data);
      setTotalLoading(false);
      return res;
    } catch (error: any) {
      console.log(error);
      toast.error(error.message || "An error occurred");
      return error;
    } finally {
      setTotalLoading(false);
    }
  };

  const addCustomToCart: object = async (
    product: any,
    quantity: number,
    buyingPrice: number,
    note?: string
  ) => {
    setTotalLoading(true);

    try {
      const res = await axios
        .post(Backend.cart + "/add-custom", {
          product: product,
          quantity: quantity,
          buying_price: buyingPrice || undefined,
          note: note || undefined,
        })
        .then((res) => res.data);

      if (res.code !== "success") {
        throw new Error(res.message);
      }

      setCart(res.data);
      setTotalLoading(false);
      return res;
    } catch (error: any) {
      console.log(error);
      toast.error(error.message || "An error occurred");
      return error;
    } finally {
      setTotalLoading(false);
    }
  };

  const removeCustomFromCart: object = async (magicNumber: number) => {
    setTotalLoading(true);

    try {
      const res = await axios
        .post(Backend.cart + "/remove-custom", {
          magic_number: magicNumber,
        })
        .then((res) => res.data);

      if (res.code !== "success") {
        throw new Error(res.message);
      }

      setCart(res.data);
      setTotalLoading(false);
      return res;
    } catch (error: any) {
      console.log(error);
      toast.error(error.message || "An error occurred");
      return error;
    } finally {
      setTotalLoading(false);
    }
  };

  const clearCart: object = async () => {
    setLoading(true);
    setTotalLoading(true);

    try {
      const res = await axios
        .post(Backend.cart + "/clear")
        .then((res) => res.data);

      if (res.code !== "success") {
        throw new Error(res.message);
      }

      setCart({});
      setTotalLoading(false);
      return res;
    } catch (error: any) {
      console.log(error);
      toast.error(error.message || "An error occurred");
      return error;
    } finally {
      setLoading(false);
      setTotalLoading(false);
    }
  };

  return {
    cart,
    loading,
    fetchCart,
    setCart,
    addToCart,
    addCustomToCart,
    totalLoading,
    clearCart,
    removeFromCart,
    removeCustomFromCart,
  };
};

type CartContextProps = () => {
  cart: any;
  loading: boolean;
  fetchCart: () => void;
  setCart: (cart: any) => void;
  addToCart: (
    productId: string,
    quantity: number,
    buyingPrice: number,
    selectedAttributes: any,
    note?: string
  ) => Promise<object>;
  addCustomToCart: (
    product: any,
    quantity: number,
    buyingPrice: number
  ) => Promise<object>;
  clearCart: () => Promise<object>;
  totalLoading: boolean;
  removeFromCart: (productId: string) => Promise<object>;
  removeCustomFromCart: (magicNumber: number) => Promise<object>;
};
export const useCartContext: CartContextProps = () => {
  return useContext(CartContext);
};

import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { Button, TextField } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import { useSmartLoadingDialogContext } from "../components/SmartLoadingDialog";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { helpers } from "../../helpers";
import AppContext from "../../AppContext";
import axios from "axios";
import Backend from "../../presets/backend";

export default function AppProfile() {
  const navigate = useNavigate();

  const context: any = useContext(AppContext);

  const smartLoading = useSmartLoadingDialogContext();

  const [user, setUser] = useState(context.user || ({} as any));

  const onFieldChange = (e: any) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const fetchUser = async () => {
    smartLoading.show();
    try {
      const res = await axios.get(Backend.profile).then((res) => res.data);

      if (res.code !== "success") {
        toast.error(res.message);
        navigate(-1);
        throw new Error(res.message);
      }

      setUser(res.data);
    } catch (error: any) {
      console.error(error);
      toast.error(
        error.response?.data?.message || error.message || "An error occurred"
      );
    } finally {
      smartLoading.dismiss();
    }
  };

  const submit = async (e: any) => {
    e.preventDefault();

    //update
    smartLoading.show();

    try {
      var userData: any = {
        // full_name: user.full_name,
        state: user.state,
        gender: user.gender,
        dob: user.dob,
        address: user.address,
        city: user.city,
        zipcode: user.zipcode,
        country: user.country,
        // phone: user.phone,
        // email: user.email, 
        // search_keywords: helpers.generateSearchKeywords(user.full_name),
      };

      // update user
      const res = await axios.post(Backend.profile, userData).then((res) => res.data);

      if (res.code !== "success") {
        throw new Error(res.message);
      }

      // update context
      context.login({ ...context.user, ...res.data });

      toast.success(res.message || "Profile updated successfully");

    } catch (error: any) {
      console.error(error);
      toast.error(
        error.response?.data?.message || error.message || "An error occurred"
      );
    } finally {
      smartLoading.dismiss();
    }
  };

  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* header */}
      <header className="z-30 fixed top-0 left-0 w-full py-2 bg-white shadow-sm dark:bg-gray-800">
        <div className="container flex items-center justify-between h-full px-5 mx-auto text-purple-600 dark:text-purple-300">
          {/* back button */}
          <ul className="flex items-center flex-shrink-0 space-x-6">
            <li className="relative">
              <IconButton
                aria-label="cart"
                color="primary"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ArrowBackIos />
              </IconButton>
            </li>
          </ul>
          {/* Page title */}
          <p className="text-xl font-semibold tracking-wide text-gray-800 text-center w-full">
            Profile
          </p>
        </div>
      </header>
      <div className="mt-[80px]"></div>

      <div className="px-4 sm:px-20">
        <form
          onSubmit={submit}
          className="flex flex-col gap-y-5"
          autoComplete="off"
        >
          {/* <!-- avatar --> */}
          <div className="relative w-20 h-20 rounded-full mx-auto">
            <img
              className="object-cover w-full h-full rounded-full"
              src={helpers.getFirstImage(user.avatar, "avatar", user.full_name)}
              alt=""
              loading="lazy"
            />
            <div
              className="absolute inset-0 rounded-full shadow-inner"
              aria-hidden="true"
            ></div>
          </div>

          <div className="flex flex-col gap-y-5 sm:grid sm:grid-cols-2 sm:gap-x-5">
            {/* name */}
            <TextField
              name="full_name"
              defaultValue={user.full_name || ""}
              disabled
              label="Name"
              type="text"
              placeholder="Name"
              className="!w-full"
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
            {/* state/province */}
            <TextField
              name="state"
              value={user.state || ""}
              onChange={onFieldChange}
              label="State/Province"
              type="text"
              placeholder="State/Province"
              className="!w-full"
              required
              InputLabelProps={{
                shrink: true,
              }}
            />

            {/* gender (optional) */}
            <TextField
              name="gender"
              value={user.gender || ""}
              onChange={onFieldChange}
              label="Gender (Optional)"
              type="text"
              placeholder="Gender"
              className="!w-full"
              InputLabelProps={{
                shrink: true,
              }}
            />
            {/* date of birth (optional) */}
            <TextField
              name="dob"
              value={user.dob || ""}
              onChange={onFieldChange}
              label="Date of Birth (Optional)"
              type="date"
              placeholder="Date of Birth"
              className="!w-full"
              InputLabelProps={{
                shrink: true,
              }}
            />
            {/* address (optional) */}
            <TextField
              name="address"
              value={user.address || ""}
              onChange={onFieldChange}
              label="Street Address (Optional)"
              type="text"
              placeholder="Address"
              className="!w-full"
              InputLabelProps={{
                shrink: true,
              }}
            />
            {/* city (optional) */}
            <TextField
              name="city"
              value={user.city || ""}
              onChange={onFieldChange}
              label="City (Optional)"
              type="text"
              placeholder="City"
              className="!w-full"
              InputLabelProps={{
                shrink: true,
              }}
            />
            {/* zip code (optional) */}
            <TextField
              name="zipcode"
              value={user.zipcode || ""}
              onChange={onFieldChange}
              label="Zip Code (Optional)"
              type="text"
              placeholder="Zip Code"
              className="!w-full"
              InputLabelProps={{
                shrink: true,
              }}
            />
            {/* country (optional) */}
            <TextField
              name="country"
              value={user.country || ""}
              onChange={onFieldChange}
              label="Country (Optional)"
              type="text"
              placeholder="Country"
              className="!w-full"
              InputLabelProps={{
                shrink: true,
              }}
            />
            {/* phone number */}
            <TextField
              name="phone"
              defaultValue={user.phone || ""}
              label="Phone Number"
              type="number"
              placeholder="Phone Number"
              className="!w-full"
              required
              disabled
              InputLabelProps={{
                shrink: true,
              }}
            />
            {/* email */}
            <TextField
              name="email"
              defaultValue={user.email || ""}
              label="Email (Optional)"
              type="text"
              placeholder="Email"
              className="!w-full"
              required
              disabled
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>

          {/* save button */}
          <Button
            variant="contained"
            size="small"
            color="primary"
            className="!px-6 !rounded-xl !normal-case !text-xl !w-full !h-[60px]"
            type="submit"
          >
            Save
          </Button>
        </form>
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
}

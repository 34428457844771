import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAuth from "./hooks/useAuth";
import ProtectedLayout from "./components/ProtectedLayout";
import NoPage from "./pages/NoPage";
import AppContext from "./AppContext";
import Home from "./pages/Home";
import Landing from "./pages/Landing";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import Products from "./pages/Products";
import Orders from "./pages/Orders";
import Users from "./pages/Users";
import Settings from "./pages/Settings";
import PushNotifications from "./pages/PushNotifications";
import Profile from "./pages/Profile";
import Categories from "./pages/Categories";
import Attributes from "./pages/Attributes";
import EmailVerification from "./pages/EmailVerification";
import Payments from "./pages/Payments";
import BackOrders from "./pages/BackOrders";
import ProtectedApp from "./App/ProtectedApp";
import MainHome from "./App/MainHome";
import AppLogin from "./App/pages/AppLogin";
import AppWelcome from "./App/pages/AppWelcome";
import React, { Suspense } from "react";
import Loader from "./components/Loader";
import { ThemeProvider, createTheme } from "@mui/material";
import AppProducts from "./App/pages/AppProducts";
import AppProduct from "./App/pages/AppProduct";
import { useCart } from "./App/hooks/useCart";
import CartContext from "./App/CartContext";
import { SmartLoadingDialog, SmartLoadingDialogContext, useSmartLoadingDialog } from "./App/components/SmartLoadingDialog";
import AppCart from "./App/pages/AppCart";
import AppAddresses from "./App/pages/AppAddresses";
import EditClient from "./App/pages/EditClient";
import Checkout from "./App/pages/Checkout";
import OrderPlaced from "./App/pages/OrderPlaced";
import AppOrder from "./App/pages/AppOrder";
import AppProfile from "./App/pages/AppProfile";
import AppAnalysis from "./App/pages/AppAnalysis";
import Clients from "./pages/Clients";

function App() {
  // global variables -- used in AppContext
  const { user, token, login, logout, loading, tokenLogin, tokenLogout } = useAuth({platform: window.location.pathname.split("/")[1]}); //admin or app
  const {cart, loading: cartLoading, fetchCart, setCart, addToCart, addCustomToCart, clearCart, removeFromCart, removeCustomFromCart} = useCart();
  const {show, dismiss, loading: smartDialogLoading} = useSmartLoadingDialog();

  const theme = createTheme({
    palette: {
      primary: {
        main: "#000000",
      },
      secondary: {
        main: "#F1F1F1",
      },
    },
  });

  return (
    <Suspense fallback={<Loader />}>
    <AppContext.Provider
      value={{ user, token, login, logout, loading, tokenLogin, tokenLogout }}
    >
      <CartContext.Provider value={{cartLoading, fetchCart, setCart, cart, addToCart, addCustomToCart, clearCart, removeFromCart, removeCustomFromCart}}>
      <SmartLoadingDialogContext.Provider value={{show, dismiss, loading: smartDialogLoading}}>
      <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          {/* NO PROTECTION */}
          <Route index element={<Landing />} />
          <Route path="/admin/login" element={<Login />} />
          <Route path="/admin/forgot-password" element={<ForgotPassword />} />
          <Route path="/email-verification" element={<EmailVerification />} />

          {/* PROTECTED */}
          <Route path="/admin" element={<ProtectedLayout />}>
            <Route index element={<Home />} />
            <Route path="products" element={<Products />} />
            <Route path="categories" element={<Categories />} />
            <Route path="attributes" element={<Attributes />} />
            <Route path="backorders" element={<BackOrders />} />
            <Route path="orders" element={<Orders />} />
            <Route path="payments" element={<Payments />} />
            <Route path="users" element={<Users />} />
            <Route path="clients" element={<Clients />} />
            <Route path="settings" element={<Settings />} />
            <Route path="notifications" element={<PushNotifications />} />
            <Route path="profile" element={<Profile />} />
            <Route path="*" element={<NoPage />} />
          </Route>

          {/* APP */}
          <Route path="/app/login" element={<AppLogin />} />
          <Route path="/app/welcome" element={<AppWelcome />} />

          <Route path="/app" element={<ProtectedApp />}>
            <Route index element={<MainHome />} />
            <Route path="home" element={<MainHome />} />
            <Route path="products" element={<AppProducts />} />
            <Route path="product/:id" element={<AppProduct />} />
            <Route path="cart" element={<AppCart />} />
            <Route path="addresses" element={<AppAddresses />} />
            <Route path="edit-client" element={<EditClient />} />
            <Route path="checkout" element={<Checkout />} />
            <Route path="order-placed" element={<OrderPlaced />} />
            <Route path="order/:id" element={<AppOrder />} />
            <Route path="profile" element={<AppProfile />} />
            <Route path="analysis" element={<AppAnalysis />} />
          </Route>

          <Route path="*" element={<NoPage />} />
        </Routes>
        <ToastContainer />
        <SmartLoadingDialog loading={smartDialogLoading} />
      </BrowserRouter>
      </ThemeProvider>
      </SmartLoadingDialogContext.Provider>
      </CartContext.Provider>
    </AppContext.Provider>
    </Suspense>
  );
}

export default App;

/* eslint-disable eqeqeq */
import Card from "@mui/material/Card";
import { helpers } from "../../helpers";

type Props = {
  item: any;
  showAttributes?: boolean
};
export default function CheckoutItemCard({ item, showAttributes }: Props) {

  return (
    <Card
      sx={{ maxWidth: "100%" }}
      className="!rounded-lg"
    >
        <div className="flex flex-row w-full gap-x-3 bg-neutral-50 rounded-lg shadow">
          <div className="ImgLike w-[200px] h-32 left-0 top-0  bg-zinc-400 rounded-lg">
            <img
              className="ImgEcheveria w-[200px] h-32  rounded-lg object-cover"
              src={
                (item.type ==  "custom" || !item.product_id) ? "/assets/images/custom_product.png" :
                helpers.getFirstImage(item.product?.images, "product")
              }
              alt="Product"
            />
          </div>
          <div className="flex flex-col items-start justify-center gap-3 w-full py-2">
            <div className="text-black font-normal leading-tight w-full">
              {item.product?.name || item.custom?.name}
            </div>
            <div
              className="text-gray-500 text-sm font-normal leading-3 w-full"
            >
              Qty: {item.quantity}
            </div>
            <div className="text-black text-sm font-bold leading-3 w-full">
              Total: N{helpers.money(item.subtotal)}
            </div>
            { showAttributes && item.product?.has_attributes && (
              <div className="text-gray-500 text-sm font-normal leading-3 border-t pt-1 w-full">
                {
                  Object.entries(item.selected_attributes || {}).map(([key, value]: any) => {
                    return <><span className="font-bold text-black">{key}: </span> <span>{value}</span></>;
                  })
                }
              </div>
            )}
            { item.note && (
              <div className="text-gray-500 text-sm font-normal leading-3">
                <span className="text-black">Note: </span> 
                {item.note}
              </div>
            )}
          </div>
        </div>
    </Card>
  );
}

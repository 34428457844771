/* eslint-disable react-hooks/exhaustive-deps */
"use client";
import axios from 'axios';
import { useEffect, useState } from 'react';
import useToken from './useToken';

type UseAxiosProps = {
    url: string;
    method: 'get' | 'post';
    body?: any;
    initialize?: boolean;
    defaultData?: any;
    toastError?: boolean;
    onReady?: (response: any) => void;
}
const useAxios = ({url, method, body = {}, initialize = true, defaultData = {}, toastError = true, onReady}: UseAxiosProps) => {
    const {token} = useToken();
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState("ERROR");
    const [message, setMessage] = useState("Something went wrong");
    const [data, setData] = useState(defaultData);
    const [responsdeData, setResponseData] = useState(defaultData);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (initialize) {
            run();
        }
    } ,[]);

    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const run = async (newURL?: string, newBody?: any) => {
        setLoading(true);
        try {
            const response = await axios[method](newURL ?? url, newBody ?? body, config);
            setLoading(false);
            setCode(response.data.code);
            setMessage(response.data.message);
            setData(response.data.data);
            setResponseData(response.data);
            setError(null);
            onReady && onReady(response.data);
        } catch (error: any) {
            setLoading(false);
            setCode(error?.response?.data?.code ?? "ERROR");
            setMessage(error?.response?.data?.message ?? "Something went wrong");
            setData(defaultData);
            setResponseData(defaultData);
            setError(error);
            if (toastError) {
                // TODO: Uncomment this line for production
                // toast.error(error?.response?.data?.message ?? "Something went wrong");
            }
        }
    }

    return {loading, code, message, data, setData, responsdeData, setResponseData, error, run, onReady};

}

type UseAxiosGetProps = {
    url: string;
    body?: any;
    initialize?: boolean;
    defaultData?: any;
    toastError?: boolean;
    onReady?: (response: any) => void;
}
const useAxiosGet = ({url, body = {}, initialize = true, defaultData = {}, toastError = true, onReady}: UseAxiosGetProps) => {
    return useAxios({url, method: 'get', body, initialize, defaultData, toastError, onReady});
}

type UseAxiosPostProps = {
    url: string;
    body?: any;
    initialize?: boolean;
    defaultData?: any;
    toastError?: boolean;
    onReady?: (response: any) => void;
}
const useAxiosPost = ({url, body = {}, initialize = true, defaultData = {}, toastError = true, onReady}: UseAxiosPostProps) => {
    return useAxios({url, method: 'post', body, initialize, defaultData, toastError, onReady});
}

export {useAxios, useAxiosGet, useAxiosPost};
/* eslint-disable eqeqeq */
import Card from "@mui/material/Card";
import { Button, CardActionArea, IconButton } from "@mui/material";
import { DeleteOutline } from "@mui/icons-material";
import { useContext } from "react";
import AppContext from "../../AppContext";

type Props = {
  client: any;
  onTap?: (client: any) => void;
  onEdit?: (client: any) => void;
  onDelete?: (client: any) => void;
};
export default function ClientCard({ client, onTap, onEdit, onDelete }: Props) {
  const context: any = useContext(AppContext);
  return (
    <Card sx={{ maxWidth: "100%" }} className="!rounded-none">
      <CardActionArea onClick={() => onTap && onTap(client)}>
        <div className="flex flex-col w-full gap-y-3 bg-neutral-50 p-4 relative">
          {client?.manager_id == context?.user?._id && (
            <div className="absolute bg-green-500 text-white p-0.5 px-2 text-xs top-2 right-2">
              you
            </div>
          )}
          <div className="font-bold text-md">{client?.full_name}</div>
          <div className="font-normal text-sm text-gray-400">
            {client?.address}
          </div>
          <div className="font-normal text-sm text-gray-400">
            {client?.phone}
          </div>
        </div>
      </CardActionArea>
      <div className="flex flex-col w-full gap-y-3 bg-neutral-50 p-4 pt-0">
        <div className="flex flex-row items-start justify-start gap-x-2 gap-y-0">
          <Button
            variant="contained"
            size="small"
            color="primary"
            className="!px-4 !rounded-xl !normal-case !text-sm !h-[30px] !mt-1"
            onClick={async () => {
              onEdit && onEdit(client);
            }}
          >
            Edit
          </Button>
          <IconButton
            aria-label="cart"
            color="primary"
            onClick={() => {
              onDelete && onDelete(client);
            }}
          >
            <DeleteOutline className="!text-gray-500" />
          </IconButton>
        </div>
      </div>
    </Card>
  );
}

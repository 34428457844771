import { useState } from "react";
import useDocumentTitle from "../hooks/userDocumentTitle";
import MainTemplate from "./MainTemplate";
import Loading from "../components/Loading";

import { ErrorHandlerComponent } from "../components/ErrorHandlerComponet";
import { toast } from "react-toastify";
import Backend from "../presets/backend";
import { useAxiosGet } from "../hooks/useAxios";
import axios from "axios";

const Settings = () => {
  useDocumentTitle("Settings");

  const [companyInfo, setCompanyInfo] = useState({} as any);
  const [appSettings, setAppSettings] = useState({} as any);
  const [maintenanceSettings, setMaintenanceSettings] = useState({} as any);

  const companyInfoInputChange = (e: any) => {
    setCompanyInfo({ ...companyInfo, [e.target.name]: e.target.value });
  };
  const appSettingsInputChange = (e: any) => {
    setAppSettings({ ...appSettings, [e.target.name]: e.target.value });
  };
  const maintenanceSettingsInputChange = (e: any) => {
    setMaintenanceSettings({
      ...maintenanceSettings,
      [e.target.name]: e.target.value,
    });
  };

  //get settings
  const { loading, error } = useAxiosGet({
    url: Backend.settings,
    defaultData: [],
    onReady(response) {
      const settings = response.data;
      //where name == company_info
      setCompanyInfo(
        settings.find((setting: any) => setting.name === "company_info")
          ?.value || {}
      );
      //where name == app_settings
      setAppSettings(
        settings.find((setting: any) => setting.name === "app_settings")
          ?.value || {}
      );
      //where name == maintenance_settings
      setMaintenanceSettings(
        settings.find((setting: any) => setting.name === "maintenance_settings")
          ?.value || {}
      );
    },
  });

  const submitSettings = async (e: any) => {
    e.preventDefault();

    const loading = toast.loading("Updating settings...");

    try {
      const res = await axios
        .post(Backend.settings, [
          { name: "company_info", value: companyInfo },
          { name: "app_settings", value: appSettings },
          { name: "maintenance_settings", value: maintenanceSettings },
        ])
        .then((res) => res.data);

      if (res.code !== "success") {
        throw new Error(res.message || "Failed to update settings.");
      }

      setCompanyInfo(res.data.find((setting: any) => setting.name === "company_info")?.value || {});
      setAppSettings(res.data.find((setting: any) => setting.name === "app_settings")?.value || {});
      setMaintenanceSettings(res.data.find((setting: any) => setting.name === "maintenance_settings")?.value || {});

      toast.success(res.message || "Settings updated successfully.");
    } catch (error: any) {
      toast.error(error.message || "Failed to update settings.");
    } finally {
      toast.dismiss(loading);
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorHandlerComponent error={error} />;
  }

  return (
    <>
      <MainTemplate title="Settings">
        <form onSubmit={submitSettings} method="POST">
          {/* General Settings */}
          <div className="mt-10">
            <div className="lg:grid lg:grid-cols-3 lg:gap-6">
              <div className="lg:col-span-1">
                <div className="px-4 lg:px-0">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Company Information
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    This is the basic company info.
                  </p>
                </div>
              </div>
              <div className="mt-5 lg:col-span-2 lg:mt-0">
                <div className="overflow-hidden shadow lg:rounded-md">
                  <div className="bg-white px-4 py-5 lg:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 lg:col-span-3">
                        <label
                          htmlFor="company_name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Company name
                        </label>
                        <input
                          name="company_name"
                          value={companyInfo.company_name || ""}
                          onChange={companyInfoInputChange}
                          type="text"
                          id="company_name"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                          placeholder="Google Inc."
                        />
                      </div>

                      <div className="col-span-6 lg:col-span-3">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Email
                        </label>
                        <input
                          name="email"
                          value={companyInfo.email || ""}
                          onChange={companyInfoInputChange}
                          type="email"
                          id="email"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                          placeholder=""
                        />
                      </div>

                      <div className="col-span-6 lg:col-span-3">
                        <label
                          htmlFor="phone"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Phone
                        </label>
                        <input
                          name="phone"
                          value={companyInfo.phone || ""}
                          onChange={companyInfoInputChange}
                          type="text"
                          id="phone"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                          placeholder=""
                        />
                      </div>

                      <div className="col-span-6 lg:col-span-3">
                        <label
                          htmlFor="address"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Address
                        </label>
                        <input
                          name="address"
                          value={companyInfo.address || ""}
                          onChange={companyInfoInputChange}
                          type="text"
                          id="address"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                          placeholder=""
                        />
                      </div>

                      <div className="col-span-6 lg:col-span-3">
                        <label
                          htmlFor="city"
                          className="block text-sm font-medium text-gray-700"
                        >
                          City
                        </label>
                        <input
                          name="city"
                          value={companyInfo.city || ""}
                          onChange={companyInfoInputChange}
                          type="text"
                          id="city"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                          placeholder=""
                        />
                      </div>

                      <div className="col-span-6 lg:col-span-3">
                        <label
                          htmlFor="state"
                          className="block text-sm font-medium text-gray-700"
                        >
                          State
                        </label>
                        <input
                          name="state"
                          value={companyInfo.state || ""}
                          onChange={companyInfoInputChange}
                          type="text"
                          id="state"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                          placeholder=""
                        />
                      </div>

                      <div className="col-span-6 lg:col-span-3">
                        <label
                          htmlFor="country"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Country
                        </label>
                        <input
                          name="country"
                          value={companyInfo.country || ""}
                          onChange={companyInfoInputChange}
                          type="text"
                          id="country"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                          placeholder=""
                        />
                      </div>

                      <div className="col-span-6 lg:col-span-3">
                        <label
                          htmlFor="zip_code"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Zip Code
                        </label>
                        <input
                          name="zip_code"
                          value={companyInfo.zip_code || ""}
                          onChange={companyInfoInputChange}
                          type="text"
                          id="zip_code"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                          placeholder=""
                        />
                      </div>

                      <div className="col-span-6 lg:col-span-3">
                        <label
                          htmlFor="facebook"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Facebook
                        </label>
                        <input
                          name="facebook"
                          value={companyInfo.facebook || ""}
                          onChange={companyInfoInputChange}
                          type="text"
                          id="facebook"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 placeholder-gray-400 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                          placeholder=""
                        />
                      </div>

                      <div className="col-span-6 lg:col-span-3">
                        <label
                          htmlFor="twitter"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Twitter
                        </label>
                        <input
                          name="twitter"
                          value={companyInfo.twitter || ""}
                          onChange={companyInfoInputChange}
                          type="text"
                          id="twitter"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 placeholder-gray-400 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                          placeholder=""
                        />
                      </div>

                      <div className="col-span-6 lg:col-span-3">
                        <label
                          htmlFor="instagram"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Instagram
                        </label>
                        <input
                          name="instagram"
                          value={companyInfo.instagram || ""}
                          onChange={companyInfoInputChange}
                          type="text"
                          id="instagram"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 placeholder-gray-400 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                          placeholder=""
                        />
                      </div>

                      <div className="col-span-6 lg:col-span-3">
                        <label
                          htmlFor="linkedin"
                          className="block text-sm font-medium text-gray-700"
                        >
                          LinkedIn
                        </label>
                        <input
                          name="linkedin"
                          value={companyInfo.linkedin || ""}
                          onChange={companyInfoInputChange}
                          type="text"
                          id="linkedin"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 placeholder-gray-400 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                          placeholder=""
                        />
                      </div>

                      <div className="col-span-6 lg:col-span-3">
                        <label
                          htmlFor="youtube"
                          className="block text-sm font-medium text-gray-700"
                        >
                          YouTube
                        </label>
                        <input
                          name="youtube"
                          value={companyInfo.youtube || ""}
                          onChange={companyInfoInputChange}
                          type="text"
                          id="youtube"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 placeholder-gray-400 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                          placeholder=""
                        />
                      </div>

                      <div className="col-span-6 lg:col-span-3">
                        <label
                          htmlFor="pinterest"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Pinterest
                        </label>
                        <input
                          name="pinterest"
                          value={companyInfo.pinterest || ""}
                          onChange={companyInfoInputChange}
                          type="text"
                          id="pinterest"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 placeholder-gray-400 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                          placeholder=""
                        />
                      </div>

                      <div className="col-span-6 lg:col-span-3">
                        <label
                          htmlFor="google"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Google
                        </label>
                        <input
                          name="google"
                          value={companyInfo.google || ""}
                          onChange={companyInfoInputChange}
                          type="text"
                          id="google"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 placeholder-gray-400 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* App Settings */}
          <div className="mt-10">
            <div className="lg:grid lg:grid-cols-3 lg:gap-6">
              <div className="lg:col-span-1">
                <div className="px-4 lg:px-0">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    App Settings
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    This is the basic app settings.
                  </p>
                </div>
              </div>
              <div className="mt-5 lg:col-span-2 lg:mt-0">
                <div className="overflow-hidden shadow lg:rounded-md">
                  <div className="bg-white px-4 py-5 lg:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 lg:col-span-3">
                        <label
                          htmlFor="current_app_version"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Current App Version
                        </label>
                        <input
                          name="current_app_version"
                          value={appSettings.current_app_version || ""}
                          onChange={appSettingsInputChange}
                          type="text"
                          id="current_app_version"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black disabled:opacity-50"
                          placeholder=""
                        />
                      </div>

                      <div className="col-span-6 lg:col-span-3">
                        <label
                          htmlFor="latest_app_version"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Latest App Version
                        </label>
                        <input
                          name="latest_app_version"
                          value={appSettings.latest_app_version || ""}
                          onChange={appSettingsInputChange}
                          type="text"
                          id="latest_app_version"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 disabled:opacity-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                          placeholder=""
                        />
                      </div>

                      <div className="col-span-6 lg:col-span-3">
                        <label
                          htmlFor="app_update_link"
                          className="block text-sm font-medium text-gray-700"
                        >
                          App Update Link
                        </label>
                        <input
                          name="app_update_link"
                          value={appSettings.app_update_link || ""}
                          onChange={appSettingsInputChange}
                          type="text"
                          id="app_update_link"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 disabled:opacity-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                          placeholder=""
                        />
                      </div>

                      <div className="col-span-6 lg:col-span-3">
                        <label
                          htmlFor="app_update_message"
                          className="block text-sm font-medium text-gray-700"
                        >
                          App Update Message
                        </label>
                        <textarea
                          name="app_update_message"
                          value={appSettings.app_update_message || ""}
                          onChange={appSettingsInputChange}
                          id="app_update_message"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 disabled:opacity-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                          placeholder=""
                        />
                      </div>

                      <div className="col-span-6 lg:col-span-3">
                        <label
                          htmlFor="app_update_force"
                          className="block text-sm font-medium text-gray-700"
                        >
                          App Update Force
                        </label>
                        <select
                          name="app_update_force"
                          value={appSettings.app_update_force || "0"}
                          onChange={appSettingsInputChange}
                          id="app_update_force"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 disabled:opacity-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                        >
                          <option value="0">No</option>
                          <option value="1">Yes</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Maintenace Settings */}
          <div className="mt-10">
            <div className="lg:grid lg:grid-cols-3 lg:gap-6">
              <div className="lg:col-span-1">
                <div className="px-4 lg:px-0">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Maintenance Settings
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    This is total system maintenance settings.
                  </p>
                </div>
              </div>
              <div className="mt-5 lg:col-span-2 lg:mt-0">
                <div className="overflow-hidden shadow lg:rounded-md">
                  <div className="bg-white px-4 py-5 lg:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 lg:col-span-3">
                        <label
                          htmlFor="maintenance_message"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Maintenance Message
                        </label>
                        <textarea
                          name="maintenance_message"
                          value={maintenanceSettings.maintenance_message || ""}
                          onChange={maintenanceSettingsInputChange}
                          id="maintenance_message"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 disabled:opacity-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                          placeholder=""
                        />
                      </div>

                      <div className="col-span-6 lg:col-span-3">
                        <label
                          htmlFor="maintenance"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Maintenance
                        </label>
                        <select
                          name="maintenance"
                          value={maintenanceSettings.maintenance || "0"}
                          onChange={maintenanceSettingsInputChange}
                          id="maintenance"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 disabled:opacity-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                        >
                          <option value="0">No</option>
                          <option value="1">Yes</option>
                        </select>
                      </div>

                      <div className="col-span-6 lg:col-span-3">
                        <label
                          htmlFor="maintenance_until"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Maintenance Until
                        </label>
                        <input
                          name="maintenance_until"
                          value={maintenanceSettings.maintenance_until || ""}
                          onChange={maintenanceSettingsInputChange}
                          type="datetime-local"
                          id="maintenance_until"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full disabled:opacity-50 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Save button layout */}
          <div className="mt-10">
            <div className="lg:grid lg:grid-cols-3 lg:gap-6">
              <div className="lg:col-span-1">
                <div className="px-4 lg:px-0">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Save Settings
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    Click the button below to save the settings.
                  </p>
                </div>
              </div>
              <div className="mt-5 lg:col-span-2 lg:mt-0">
                <div className="overflow-hidden shadow lg:rounded-md">
                  <div className="bg-white px-4 py-5 lg:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6">
                        <button
                          type="submit"
                          className="w-full text-white bg-black hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-3 text-center dark:bg-blue-600 dark:hover:bg-gray-700 dark:focus:ring-black"
                        >
                          Save Settings
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </MainTemplate>
    </>
  );
};

export default Settings;

import { useEffect, useState } from "react";
import axios from "axios";
import Backend from "../../presets/backend";

type UseFireDocProps = {
  id: string;
  initialize?: boolean;
};
const useOrder = ({ id, initialize = false }: UseFireDocProps) => {
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState({} as any);

  useEffect(() => {
    if (initialize) {
      runQuery(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const runQuery = async (id: any) => {
    setLoading(true);
    try {
      const url = Backend.front_orders + "/" + id;
      const res = await axios.get(url).then((res) => res.data);

      if (res.code !== "success") {
        throw new Error(res.message);
      }
      setOrder(res.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    order,
    setOrder,
    runQuery,
  };
};


const useOrders = ({ initialize = false, params = {} }) => {
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([] as any);
  const [pagination, setPagination] = useState({} as any);

  //for sales manager
  const [totalSales, setTotalSales] = useState(0);
  const [totalPending, setTotalPending] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);

  const [totalSalesAmount, setTotalSalesAmount] = useState(0);
  const [totalPendingAmount, setTotalPendingAmount] = useState(0);
  const [totalPaidAmount, setTotalPaidAmount] = useState(0);

  useEffect(() => {
    if (initialize) {
      runQuery(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const runQuery = async (params: any) => {
    setLoading(true);

    try {
      const url =
        Backend.front_orders + "?" + new URLSearchParams(params).toString();
      const res = await axios.get(url).then((res) => res.data);

      if (res.code !== "success") {
        throw new Error(res.message);
      }

      //clear totals
      var tSales = 0;
      var tPending = 0;
      var tPaid = 0;
      var tSalesAmount = 0;
      var tPendingAmount = 0;
      var tPaidAmount = 0;

      res.data.forEach((order: any) => {
        tSales++;
        tSalesAmount += order.total;

        if (order.status === "pending") {
          tPending++;
          tPendingAmount += order.total;
        } else if (order.status === "paid") {
          tPaid++;
          tPaidAmount += order.total;
        }
      });

      setTotalSales(tSales);
      setTotalPending(tPending);
      setTotalPaid(tPaid);
      setTotalSalesAmount(tSalesAmount);
      setTotalPendingAmount(tPendingAmount);
      setTotalPaidAmount(tPaidAmount);

      setOrders(res.data);

      setPagination(res.pagination);

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    orders,
    setOrders,
    runQuery,
    pagination,
    totalSales,
    totalPending,
    totalPaid,
    totalSalesAmount,
    totalPendingAmount,
    totalPaidAmount,
  };
};

export { useOrder, useOrders };

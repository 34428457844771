import { useEffect, useState } from "react";
import axios from "axios";
import Backend from "../../presets/backend";

type UseFireDocProps = {
  id: string;
  initialize?: boolean;
};
const usePayment = ({ id, initialize = false }: UseFireDocProps) => {
  const [loading, setLoading] = useState(true);
  const [payment, setPayment] = useState({} as any);

  useEffect(() => {
    if (initialize) {
      runQuery(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const runQuery = async (id: string) => {
    setLoading(true);
    setPayment(null);

    try {
      const url = Backend.front_payments + "/" + id;
      const res = await axios.get(url).then((res) => res.data);

      if (res.code !== "success") {
        console.log(res);
        return;
      }

      setPayment(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    payment,
    setPayment,
    runQuery,
  };
};

const usePayments = ({ initialize = false, params = {} }) => {
  const [loading, setLoading] = useState(true);
  const [payments, setPayments] = useState([] as any);
  const [pagination, setPagination] = useState({} as any);

  const [totalSales, setTotalSales] = useState(0);
  const [totalSalesAmount, setTotalSalesAmount] = useState(0);
  const [totalPending, setTotalPending] = useState(0);
  const [totalPendingAmount, setTotalPendingAmount] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);
  const [totalPaidAmount, setTotalPaidAmount] = useState(0);
  const [totalProcessing, setTotalProcessing] = useState(0);
  const [totalProcessingAmount, setTotalProcessingAmount] = useState(0);
  const [totalCancelled, setTotalCancelled] = useState(0);
  const [totalCancelledAmount, setTotalCancelledAmount] = useState(0);
  const [totalRefunded, setTotalRefunded] = useState(0);
  const [totalRefundedAmount, setTotalRefundedAmount] = useState(0);

  useEffect(() => {
    if (initialize) {
      runQuery(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const runQuery = async (params: any) => {
    setLoading(true);

    try {
      const res = await axios
        .get(
          Backend.front_payments + "?" + new URLSearchParams(params).toString()
        )
        .then((res) => res.data);

      if (res.code !== "success") {
        console.log(res);
        return;
      }

      //clear totals
      var tSales = 0;
      var tSalesAmount = 0;
      var tPending = 0;
      var tPendingAmount = 0;
      var tPaid = 0;
      var tPaidAmount = 0;
      var tProcessing = 0;
      var tProcessingAmount = 0;
      var tCancelled = 0;
      var tCancelledAmount = 0;
      var tRefunded = 0;
      var tRefundedAmount = 0;

      res.data.forEach((payment: any) => {
        //for sales manager
        if (payment.status === "paid") {
          tPaid++;
          tPaidAmount = tPaidAmount + parseFloat(payment.amount);
        } else if (payment.status === "pending") {
          tPending++;
          tPendingAmount = tPendingAmount + parseFloat(payment.amount);
        } else if (payment.status === "processing") {
          tProcessing++;
          tProcessingAmount = tProcessingAmount + parseFloat(payment.amount);
        } else if (
          payment.status === "cancelled" ||
          payment.status === "canceled"
        ) {
          tCancelled++;
          tCancelledAmount = tCancelledAmount + parseFloat(payment.amount);
        } else if (payment.status === "refunded") {
          tRefunded++;
          tRefundedAmount = tRefundedAmount + parseFloat(payment.amount);
        }

        tSales++;
        tSalesAmount = tSalesAmount + parseFloat(payment.amount);
      });

      setPayments(res.data);

      //for sales manager
      setTotalSales(tSales);
      setTotalSalesAmount(tSalesAmount);
      setTotalPending(tPending);
      setTotalPendingAmount(tPendingAmount);
      setTotalPaid(tPaid);
      setTotalPaidAmount(tPaidAmount);
      setTotalProcessing(tProcessing);
      setTotalProcessingAmount(tProcessingAmount);
      setTotalCancelled(tCancelled);
      setTotalCancelledAmount(tCancelledAmount);
      setTotalRefunded(tRefunded);
      setTotalRefundedAmount(tRefundedAmount);

      setPagination(res.pagination);
      
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    payments,
    setPayments,
    runQuery,
    pagination,
    //for sales manager
    totalSales,
    totalSalesAmount,
    totalPending,
    totalPendingAmount,
    totalPaid,
    totalPaidAmount,
    totalProcessing,
    totalProcessingAmount,
    totalCancelled,
    totalCancelledAmount,
    totalRefunded,
    totalRefundedAmount,
  };
};

export { usePayment, usePayments };

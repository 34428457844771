import { useEffect, useState } from "react";
import Backend from "../../presets/backend";
import axios from "axios";

type UseFireDocProps = {
  id: string;
  initialize?: boolean;
};
const useCategory = ({ id, initialize = false }: UseFireDocProps) => {
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState({} as any);

  useEffect(() => {
    if (initialize) {
      runQuery(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const runQuery = async (id: any) => {
    setLoading(true);
    try {
      const url = Backend.categories + "/" + id;
      const res = await axios.get(url).then((res) => res.data);

      if (res.code !== "success") {
        throw new Error(res.message);
      }
      setCategory(res.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    category,
    setCategory,
    runQuery,
  };
};

const useCategories = ({ initialize = false }) => {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([] as any);

  useEffect(() => {
    if (initialize) {
      runQuery({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const runQuery = async (params: any) => {
    setLoading(true);
    try {
      const url =
        Backend.categories + "?" + new URLSearchParams(params).toString();
      const res = await axios.get(url).then((res) => res.data);

      if (res.code !== "success") {
        throw new Error(res.message);
      }
      setCategories(res.data);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    categories,
    setCategories,
    runQuery,
  };
};

export { useCategory, useCategories };

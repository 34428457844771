/* eslint-disable react-hooks/exhaustive-deps */
"use client";

import useDocumentTitle from "../hooks/userDocumentTitle";
import MainTemplate from "./MainTemplate";
import NotificationsTable from "../components/NotificationsTable";
import { useAxiosGet } from "../hooks/useAxios";
import Backend from "../presets/backend";

const PushNotifications = () => {
  useDocumentTitle("Push Notifications");

  //get notifications
  const {
    data: notifications,
    loading: notificationsLoading,
    ...notificationsRest
  } = useAxiosGet({
    url: Backend.notifications,
    defaultData: [],
  });

  const onTableUpdated = (notification: any) => {
    notificationsRest.run();
  };

  return (
    <>
      <MainTemplate title="Push Notifications">
        {/* <!-- Notifications --> */}
        <NotificationsTable
          notifications={notifications}
          onTableUpdated={onTableUpdated}
          loading={notificationsLoading}
        />

        <br />
        <br />
        <br />
        <br />
      </MainTemplate>
    </>
  );
};

export default PushNotifications;

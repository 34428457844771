
var url = "https://dipsyfiber.onrender.com"; //for production

if (window.location.hostname === "localhost") {
    console.log("%cRunning in development mode", "color: yellow; font-size: 10px; font-weight: bold;", window.location.hostname);
    url = "http://localhost:3001";
}

var Backend = {
    base: url,

    //auth
    login: url + "/api/login",

    //account
    profile: url + "/api/profile",
    changePassword: url + "/api/change-password",

    //cart
    cart: url + "/api/cart",

    //products
    products: url + "/api/products",
    attributes: url + "/api/attributes",
    categories: url + "/api/categories",

    //notifications
    notifications: url + "/api/notifications",
    admin_notifications: url + "/api/admin/notifications",

    //clients
    clients: url + "/api/admin/clients",
    front_clients: url + "/api/clients",

    //order
    front_orders: url + "/api/orders",
    //payments
    front_payments: url + "/api/payments",

    //users
    front_users: url + "/api/users",

    //admin
    users: url + "/api/admin/users",
    settings: url + "/api/admin/settings",
    orders: url + "/api/admin/orders",
    payments: url + "/api/admin/payments",
    admin_products: url + "/api/admin/products",
    admin_categories: url + "/api/admin/categories",
    admin_attributes: url + "/api/admin/attributes",
    stats: url + "/api/admin/stats",






}

export default Backend;
import MainTemplate from "./MainTemplate";
import useDocumentTitle from "../hooks/userDocumentTitle";
import UsersTable from "../components/UsersTable";
import { useAxiosGet } from "../hooks/useAxios";
import Backend from "../presets/backend";
import { toast } from "react-toastify";
import {
  collection,
  getDocs,
  query,
} from "firebase/firestore";
import { db } from "..";

const Users = () => {
  useDocumentTitle("Users");

  //get users
  const {
    data: users,
    loading,
    ...usersRest
  } = useAxiosGet({
    url: Backend.users,
    defaultData: [],
  });

  var params: any = {};

  const fetchUsers = async (name = "", fromDate = "", toDate = "") => {
    

    if (name && name.length > 0) {
      params.search = name;
    } else if (fromDate && fromDate.length > 0 && toDate && toDate.length > 0) {
      params.from = fromDate;
      params.to = toDate;
    }

    usersRest.run(Backend.users + "?" + new URLSearchParams(params).toString());
  };

  const onSearchNameButtonClicked = () => {
    (document.getElementById("from_date") as any).value = "";
    (document.getElementById("to_date") as any).value = "";
    const name: any = (document.getElementById("product_name") as any)?.value;
    fetchUsers(name);
  };

  const onSearchDateButtonClicked = () => {
    (document.getElementById("product_name") as any).value = "";
    const fromDate: any = (document.getElementById("from_date") as any)?.value;
    const toDate: any = (document.getElementById("to_date") as any)?.value;
    fetchUsers("", fromDate, toDate);
  };

  const onNextPage = () => {
    const next = parseInt(usersRest.responsdeData?.pagination?.current_page) + 1;
    usersRest.run(Backend.users + "?" + new URLSearchParams({ ...params, page: next }).toString());
}

const onPrevPage = () => {
    const prev = parseInt(usersRest.responsdeData?.pagination?.current_page) - 1;
    usersRest.run(Backend.users + "?" + new URLSearchParams({ ...params, page: prev }).toString());
}

  const onTableUpdated = (user: any) => {
    fetchUsers();
  };

  const exportFirebaseToJson = async () => {
    const loading = toast.loading("Exporting docs to JSON file...");

    try {
      let docs: any[] = [];
      const docsRef = collection(db, "users");
      const q = query(docsRef);

      await getDocs(q).then((snapshot) => {
        snapshot.forEach((doc) => {
          docs.push({ ...doc.data(), id: doc.id });
        });
      });

      const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
        JSON.stringify(docs)
      )}`;
      const link = document.createElement("a");
      link.href = jsonString;
      link.download = "data.json";

      link.click();
    } catch (error: any) {
      console.log(error);
      toast.error("Failed to export docs to JSON file");
    } finally {
      toast.dismiss(loading);
    }
  };

  return (
    <MainTemplate title="Users">
      {/* <!-- search filter --> */}
      <div className="flex flex-col lg:flex-row items-start lg:items-center justify-start lg:justify-center w-full gap-4 mb-5">
        <div className="flex flex-row w-full lg:w-auto">
          <input
            type="search"
            id="product_name"
            onKeyDown={(e: any) => {
              if (e.key === "Enter") {
                onSearchNameButtonClicked();
              }
            }}
            className="block p-2.5 w-full  text-sm text-gray-900 bg-gray-50 rounded-l-lg appearance-none border border-gray-300 focus:ring-black focus:border-black dark:bg-gray-700 dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-black"
            placeholder="Search name"
          />
          <button
            onClick={onSearchNameButtonClicked}
            type="button"
            className="p-2.5 text-sm font-medium text-white bg-black rounded-r-lg border border-black hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-black dark:hover:bg-gray-900 dark:focus:ring-blue-800"
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
            <span className="sr-only">Search</span>
          </button>
        </div>
        <div className="flex flex-row w-full lg:w-auto">
          <input
            type="date"
            id="from_date"
            className="block p-2.5 w-full  text-sm text-gray-900 bg-gray-50 rounded-tl-lg appearance-none rounded-bl-lg border border-r-0 border-gray-300 focus:ring-black focus:border-black dark:bg-gray-700 dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-black"
            placeholder="From"
          />
          <input
            type="date"
            id="to_date"
            className="block p-2.5 w-full  text-sm text-gray-900 bg-gray-50 border appearance-none border-gray-300 focus:ring-black focus:border-black dark:bg-gray-700 dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-black"
            placeholder="To"
          />
          <button
            onClick={onSearchDateButtonClicked}
            type="button"
            className="p-2.5 text-sm font-medium text-white bg-black rounded-r-lg border border-black hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-black dark:hover:bg-gray-900 dark:focus:ring-blue-800"
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
            <span className="sr-only">Search</span>
          </button>
        </div>
      </div>

      <UsersTable
        users={users}
        loading={loading}
        pagination={usersRest.responsdeData?.pagination}
          onNextPage={onNextPage}
          onPrevPage={onPrevPage}
        onTableUpdated={onTableUpdated}
      />

      {/* export button */}
      <div className="xflex flex-row w-full items-center justify-center hidden">
        <button
          onClick={exportFirebaseToJson}
          type="button"
          className="p-2.5 text-sm font-medium text-white bg-black rounded-lg border border-black hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-black dark:hover:bg-gray-900 dark:focus:ring-blue-800"
        >
          Export Docs to JSON
        </button>
      </div>
    </MainTemplate>
  );
};

export default Users;

/* eslint-disable eqeqeq */
import Card from "@mui/material/Card";
import { Button, IconButton } from "@mui/material";
import { helpers } from "../../helpers";
import { useState } from "react";
import { Add, Remove } from "@mui/icons-material";
import { useCartContext } from "../hooks/useCart";
import { toast } from "react-toastify";

type Props = {
  item: any;
  onTap?: (item: any) => void;
};
export default function CartItemCard({ item, onTap }: Props) {
  const cartContext = useCartContext();

  const [price] = useState(item.price);
  const [quantity, setQuantity] = useState(item.quantity);

  return (
    <Card
      sx={{ maxWidth: "100%" }}
      className="!rounded-lg"
      onClick={() => onTap && onTap(item)}
    >
      <div className="flex flex-row w-full gap-x-3 bg-neutral-50 rounded-lg shadow">
        <div className="ImgLike w-[200px] h-32 left-0 top-0  bg-zinc-400 rounded-lg">
          <img
            className="ImgEcheveria w-[200px] h-32  rounded-lg !object-cover"
            src={
              item.type == "custom" || !item.product_id
                ? "/assets/images/custom_product.png"
                : helpers.getFirstImage(item.product?.images, "product")
            }
            alt="Product"
          />
        </div>
        <div className="flex flex-col items-start justify-center gap-3 w-full py-2">
          <div className="text-black font-normal leading-tight w-full">
          {item.product?.name || item.custom?.name}
          </div>
          <div
            className={`text-gray-500 text-sm font-normal leading-3 w-full ${
              quantity < 2 && "hidden"
            }`}
          >
            Unit Price: N{helpers.money(item.price)}
          </div>
          <div className="text-black text-sm font-bold leading-3 w-full">
            Total: N{helpers.money(price * quantity)}
          </div>
          <div className="flex flex-row items-center justify-center gap-x-2">
            {/* minus */}
            <IconButton
              aria-label="delete"
              className="!bg-black !h-[30px] !w-[30px]"
              onClick={() => {
                if (quantity > 1) {
                  setQuantity(quantity - 1);
                  if (item.type == "custom" || !item.product_id) {
                    cartContext.addCustomToCart(item, quantity - 1, item.price);
                  } else {
                    cartContext.addToCart(
                      item.product_id,
                      quantity - 1,
                      item.price,
                      item.selectedAttributes || undefined
                    );
                  }
                }
              }}
            >
              <Remove className="!text-white" />
            </IconButton>
            {/* input */}
            <div className="text-black text-xl font-bold leading-3">
              {quantity}
            </div>
            {/* plus */}
            <IconButton
              aria-label="delete"
              color="secondary"
              className="!bg-white !shadow-md !h-[30px] !w-[30px]"
              onClick={() => {
                setQuantity(quantity + 1);
                if (item.type == "custom" || !item.product_id) {
                  cartContext.addCustomToCart(item, quantity + 1, item.price);
                } else {
                  cartContext.addToCart(
                    item.product_id,
                    quantity + 1,
                    item.price,
                    item.selectedAttributes || undefined
                  );
                }
              }}
            >
              <Add className="!text-black" />
            </IconButton>
            {/* delete */}
            <Button
              variant="contained"
              size="small"
              color="primary"
              className="!ml-3 !px-4 !rounded-xl !normal-case !text-sm !w-full !h-[30px]"
              onClick={async () => {
                const loading = toast.loading("Removing from cart...");
                var res: any = null;

                if (item.type == "custom" || !item.product_id) {
                  res = await cartContext.removeCustomFromCart(
                    item.magic_number
                  );
                } else {
                  res = await cartContext.removeFromCart(item.product_id);
                }

                if (res?.code !== "success") {
                  //error already handled in hook
                  return;
                }

                toast.update(loading, {
                  render: "Removed from cart",
                  type: "success",
                  isLoading: false,
                  autoClose: 2000,
                });
              }}
            >
              Delete
            </Button>
          </div>
          {item.note && (
            <div className="text-gray-500 text-sm font-normal leading-3">
              <span className="text-black">Note: </span>
              {item.note}
            </div>
          )}
        </div>
      </div>
    </Card>
  );
}

import { useContext, useRef, useState } from "react";
import { usePayments } from "../hooks/usePayments";
import { Shimmer } from "react-shimmer";
import InvoiceCard from "../components/InvoiceCard";
import { InvoiceDialog } from "../components/InvoiceDialog";
import { IconButton } from "@mui/material";
import { CustomProductDialog } from "../components/CustomProductDialog";
import { useSmartLoadingDialogContext } from "../components/SmartLoadingDialog";
import { useCartContext } from "../hooks/useCart";
import { toast } from "react-toastify";
import { AddedToCartDialog } from "../pages/AppProduct";
import { Add } from "@mui/icons-material";
import AppContext from "../../AppContext";

import Badge, { BadgeProps } from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import { ShoppingCartOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Currency from "../../components/Currency";
import { helpers } from "../../helpers";

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 0,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

export default function AppInvoices() {
  const navigate = useNavigate();

  const context: any = useContext(AppContext);

  const cartContext = useCartContext();

  const [search, setSearch] = useState("");

  const [params, setParams] = useState({
    per_page: 20,
  } as any);

  const { payments, loading, runQuery, ...paymentRest } = usePayments({
    initialize: true,
    params: params,
  });


  const smartLoading = useSmartLoadingDialogContext();

  const invoiceRef = useRef(null as any);

  const onInvoiceDone = () => {};

  const [showCustomDialog, setShowCustomDialog] = useState(false);
  const [showAddedToCartDialog, setShowAddedToCartDialog] = useState(false);
  const [cartProductData, setCartProductData] = useState({} as any);

  const openCart = () => {
    navigate("/app/cart");
  };

  const onSearchDateButtonClicked = () => {
    const fromDate: any = (document.getElementById("from_date") as any)?.value;
    const toDate: any = (document.getElementById("to_date") as any)?.value;
    //if any of the time is not provide return
    if (fromDate.length < 1 || toDate.length < 1) {
      return;
    }
    setParams({ ...params, from: fromDate, to: toDate });
    runQuery({ ...params, from: fromDate, to: toDate });
  };


  const onNextPage = () => {
    setParams({ ...params, page: paymentRest.pagination?.current_page + 1 });
    runQuery({ ...params, page: paymentRest.pagination?.current_page + 1 });
  }
  const onPrevPage = () => {
    const page = paymentRest.pagination?.current_page - 1;
    setParams({ ...params, page: page < 1 ? 1 : page });
    runQuery({ ...params, page: page < 1 ? 1 : page });
  }


  const onAddToCart = async (product: any, quantity: number) => {
    if (product) {
      // TODOz: set attributes
      var negotiatedPrice: number = parseFloat(product.price);

      var buyingPrice = negotiatedPrice / quantity;

      try {
        smartLoading.show();

        await cartContext.addCustomToCart(
          {
            ...product,
            //generate magic number for identifying cart items
            magic_number: Math.floor(Math.random() * 1000000000),
            type: "custom",
            user: context.user || {},
            quantity: parseInt(quantity.toString()),
          },
          parseInt(quantity.toString()),
          buyingPrice
        );

        toast.success("Added to cart");
        setShowAddedToCartDialog(true);

        setCartProductData({
          ...product,
          quantity,
          subtotal: buyingPrice * quantity,
        });
      } catch (error: any) {
        toast.error(error?.message || "An error occured");
      } finally {
        smartLoading.dismiss();
      }
    }
  };

  return (
    <>
      <header className="z-30 fixed top-0 left-0 w-full py-4 bg-white shadow-md dark:bg-gray-800">
        <div className="container flex items-center justify-between h-full px-6 mx-auto text-purple-600 dark:text-purple-300">
          {/* <!-- Search input --> */}
          <div className="flex justify-center flex-1">
            <div className="relative w-full max-w-xl mr-2 focus-within:text-purple-500">
              <div className="absolute inset-y-0 flex items-center pl-2">
                <svg
                  className="w-4 h-4 text-gray-400"
                  area-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <input
                className="w-full pl-8 py-2 pr-2 text-lg text-gray-700 placeholder:text-gray-400 bg-white border border-gray-400 rounded-lg dark:placeholder-gray-500 dark:focus:shadow-outline-gray dark:focus:placeholder-gray-600 dark:bg-gray-700 dark:text-gray-200 focus:placeholder-gray-500 focus:bg-white focus:border-purple-300 focus:outline-none focus:shadow-outline-purple form-input"
                type="text"
                placeholder="Search by name or ID"
                aria-label="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    //is search value is a number, search by id
                    if (!isNaN(Number(search)) && search.trim() !== "") {
                      setParams({ ...params, id: search });
                      runQuery({ ...params, id: search });
                      return;
                    }
                    setParams({ ...params, search: search });
                    runQuery({ ...params, search: search });
                  }
                }}
              />
            </div>
          </div>
          <ul className="flex items-center flex-shrink-0 space-x-6">
            <li className="relative">
              <IconButton
                aria-label="cart"
                color="primary"
                onClick={() => openCart()}
              >
                <StyledBadge
                  badgeContent={cartContext.cart.items?.length || 0}
                  color="error"
                >
                  <ShoppingCartOutlined />
                </StyledBadge>
              </IconButton>
            </li>
          </ul>
        </div>
      </header>

      <div className="mt-[80px] w-full"></div>

      {/* <!-- search filter --> */}
      <div className="pt-3 px-3 flex flex-col lg:flex-row items-start lg:items-center justify-start lg:justify-center w-full gap-4">

        <div className="flex flex-row w-full lg:w-auto">
          <input
            type="date"
            id="from_date"
            className="block p-2.5 w-full  text-sm text-gray-900 bg-gray-50 rounded-tl-lg appearance-none rounded-bl-lg border border-r-0 border-gray-300 focus:ring-black focus:border-black dark:bg-gray-700 dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-black"
            placeholder="From"
          />
          <input
            type="date"
            id="to_date"
            className="block p-2.5 w-full  text-sm text-gray-900 bg-gray-50 border appearance-none border-gray-300 focus:ring-black focus:border-black dark:bg-gray-700 dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-black"
            placeholder="To"
          />
          <button
            onClick={onSearchDateButtonClicked}
            type="button"
            className="p-2.5 text-sm font-medium text-white bg-black rounded-r-lg border border-black hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-black dark:hover:bg-gray-900 dark:focus:ring-blue-800"
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
            <span className="sr-only">Search</span>
          </button>
        </div>
        {/* <div className="hidden lg:flex flex-row w-full lg:w-auto">
            <ExportExcelButton
              excelData={payments.map((payment) => {
                return {
                  ID: payment.doc_number,
                  "Sales Manager": payment.sales_manager?.full_name,
                  Amount: payment.amount,
                  "Outstanding": (() => {
                    var totalPaid = parseFloat(payment.total_paid);
                    var totalAmount = parseFloat(payment.amount);
                    if (totalPaid < totalAmount) {
                      return totalAmount - totalPaid;
                    }
                    return 0;
                  })(),
                  Client: payment.client?.full_name || payment.user?.full_name,
                  "Client Status": payment.is_new_client ? "New" : "Old",
                  Email: payment.client?.email || payment.user?.email,
                  Phone: payment.client?.phone || payment.user?.phone,
                  "Payment Status": payment.status,
                  "Date": payment.created_at.toDate().toLocaleString(),
                };
              })}
              fileName={`Invoices-${new Date().toLocaleDateString()}`}
              className="w-full text-white mr-2 bg-black py-1.5 ml-4 text-lg px-5 rounded hover:bg-blue-600 flex flex-row items-center justify-center"
              data-modal-toggle="add-modal"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="inline h-6 w-6 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                />
              </svg>
              Export
            </ExportExcelButton>
          </div> */}
      </div>

      {/* stats */}
      {(!loading && (params.search || params.id || params.from || params.to)) && (
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-2 mt-2 px-3">
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all w-full">
            <div className="bg-white p-4">
              <div className="lg:flex lg:items-start">
                <div className="text-center lg:mt-0 lg:ml-2 lg:text-left">
                  <h3 className="text-sm leading-6 font-medium text-gray-400">
                    Total Amount ({payments.length})
                  </h3>
                  <p className="text-xl font-bold text-black">
                    <Currency />
                    {helpers.money(paymentRest.totalSalesAmount)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all w-full">
            <div className="bg-white p-4">
              <div className="lg:flex lg:items-start">
                <div className="text-center lg:mt-0 lg:ml-2 lg:text-left">
                  <h3 className="text-sm leading-6 font-medium text-gray-400">
                    Total Paid ({paymentRest.totalPaid}/{payments.length})
                  </h3>
                  <p className="text-xl font-bold text-black">
                    <Currency />
                    {helpers.money(paymentRest.totalPaidAmount)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all w-full">
            <div className="bg-white p-4">
              <div className="lg:flex lg:items-start">
                <div className="text-center lg:mt-0 lg:ml-2 lg:text-left">
                  <h3 className="text-sm leading-6 font-medium text-gray-400">
                    Total Pending ({paymentRest.totalPending}/{payments.length})
                  </h3>
                  <p className="text-xl font-bold text-black">
                    <Currency />
                    {helpers.money(paymentRest.totalPendingAmount)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all w-full">
            <div className="bg-white p-4">
              <div className="lg:flex lg:items-start">
                <div className="text-center lg:mt-0 lg:ml-2 lg:text-left">
                  <h3 className="text-sm leading-6 font-medium text-gray-400">
                    Total Processing ({paymentRest.totalProcessing}/
                    {payments.length})
                  </h3>
                  <p className="text-xl font-bold text-black">
                    <Currency />
                    {helpers.money(paymentRest.totalProcessingAmount)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all w-full">
            <div className="bg-white p-4">
              <div className="lg:flex lg:items-start">
                <div className="text-center lg:mt-0 lg:ml-2 lg:text-left">
                  <h3 className="text-sm leading-6 font-medium text-gray-400">
                    Total Cancelled ({paymentRest.totalCancelled}/
                    {payments.length})
                  </h3>
                  <p className="text-xl font-bold text-black">
                    <Currency />
                    {helpers.money(paymentRest.totalCancelledAmount)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all w-full">
            <div className="bg-white p-4">
              <div className="lg:flex lg:items-start">
                <div className="text-center lg:mt-0 lg:ml-2 lg:text-left">
                  <h3 className="text-sm leading-6 font-medium text-gray-400">
                    Total Refunded ({paymentRest.totalRefunded}/
                    {payments.length})
                  </h3>
                  <p className="text-xl font-bold text-black">
                    <Currency />
                    {helpers.money(paymentRest.totalRefundedAmount)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* page */}
      <div className="px-4 py-4 w-full sm:px-20">
        {/* filter */}
        {/* <div className="font-normal text-lg flex gap-x-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z"
            />
          </svg>
          <span>All, by Creation Date, Descending</span>
        </div> */}
        {/* invoices */}
        <div className="mt-3"></div>
        {loading ? (
          <ShimmerPage />
        ) : (
          <>
            {/* empty clients placeholder */}
            {payments.length < 1 && (
              <div className="flex flex-col items-center justify-center gap-y-2 mt-[50px] mb-[100px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-[80px] h-[80px] text-gray-400"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M18.364 18.364A9 9 0 0 0 5.636 5.636m12.728 12.728A9 9 0 0 1 5.636 5.636m12.728 12.728L5.636 5.636"
                  />
                </svg>

                <div className="text-2xl text-gray-400 font-normal">
                  No invoices found
                </div>
              </div>
            )}
            {payments.length > 0 && (
              <div>
                <div className="space-y-5 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-3">
                  {payments.map((payment: any) => {
                    return (
                      <InvoiceCard
                        key={payment._id}
                        payment={payment}
                        onTap={(payment) => {
                          invoiceRef.current?.open(payment._id);
                        }}
                      />
                    );
                  })}
                </div>
                <div className="grid grid-cols-2 px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase bpayment-t dark:bpayment-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                  <span className="flex items-center">
                    {/* format Showing x - xx of xxx */}
                    { paymentRest.pagination?.from} - {paymentRest.pagination?.to} of{" "}
                    {paymentRest.pagination?.total}
                  </span>
                  {/* <!-- Pagination --> */}
                  <span className="flex lg:mt-auto justify-end">
                    <nav aria-label="Table navigation">
                      <ul className="inline-flex items-center">
                        <li>
                          <button
                            type="button"
                            onClick={onPrevPage}
                            className="px-3 py-1 border rounded-md rounded-l-lg focus:outline-none focus:shadow-outline-purple hover:bg-gray-200"
                            aria-label="Previous"
                          >
                            <svg
                              aria-hidden="true"
                              className="w-4 h-4 fill-current"
                              viewBox="0 0 20 20"
                            >
                              <path
                                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                clipRule="evenodd"
                                fillRule="evenodd"
                              ></path>
                            </svg>
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            className="px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-purple"
                          >
                            {paymentRest.pagination?.current_page}
                          </button>
                        </li>
                        <li>
                          <button
                            onClick={onNextPage}
                            type="button"
                            className="px-3 py-1 border rounded-md rounded-r-lg focus:outline-none focus:shadow-outline-purple hover:bg-gray-200"
                          >
                            <svg
                              className="w-4 h-4 fill-current"
                              aria-hidden="true"
                              viewBox="0 0 20 20"
                            >
                              <path
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clipRule="evenodd"
                                fillRule="evenodd"
                              ></path>
                            </svg>
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </span>
                </div>
                <br/>
                <br/>
                <br/>
                <br/>
              </div>
            )}
          </>
        )}
      </div>

      {/* floating button */}
      <IconButton
        className="!fixed !bottom-[90px] !right-0 !m-4 !bg-black !z-40"
        color="primary"
        size="large"
        onClick={() => {
          setShowCustomDialog(true);
        }}
      >
        <Add className="!text-white" fontSize="large" />
      </IconButton>

      {/* invoice dialog */}
      <InvoiceDialog refx={invoiceRef} done={onInvoiceDone} />
      {/* custom product dialog */}
      <CustomProductDialog
        show={showCustomDialog}
        setShow={setShowCustomDialog}
        onAddToCart={onAddToCart}
      />
      {/* add to cart dialog */}
      <AddedToCartDialog
        show={showAddedToCartDialog}
        setShow={setShowAddedToCartDialog}
        item={cartProductData}
        onContinueShopping={() => {
          setShowAddedToCartDialog(false);
        }}
      />
    </>
  );
}

const ShimmerPage = () => {
  return (
    <>
      <div className="space-y-4 sm:space-y-0 w-full sm:grid sm:grid-cols-2 sm:gap-3">
        {[1, 2, 3, 4].map((item: any) => {
          return (
            <Shimmer
              key={item}
              width={130}
              height={120}
              className="rounded-lg !w-full"
            />
          );
        })}
      </div>
    </>
  );
};

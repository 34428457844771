import { useEffect, useState } from "react";
import Backend from "../../presets/backend";
import axios from "axios";

type UseFireDocProps = {
  id: string;
  initialize?: boolean;
};
const useClient = ({ id, initialize = false }: UseFireDocProps) => {
  const [loading, setLoading] = useState(true);
  const [client, setClient] = useState({} as any);

  useEffect(() => {
    if (initialize) {
      runQuery(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const runQuery = async (id: any) => {
    setLoading(true);
    try {
      const url = Backend.front_clients + "/" + id;
      const res = await axios.get(url).then((res) => res.data);

      if (res.code !== "success") {
        throw new Error(res.message);
      }
      setClient(res.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    client,
    setClient,
    runQuery,
  };
};

const useClients = ({ initialize = false, params = {} }) => {
  const [loading, setLoading] = useState(true);
  const [clients, setClients] = useState([] as any);
  const [pagination, setPagination] = useState({} as any);

  useEffect(() => {
    if (initialize) {
      runQuery(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const runQuery = async (params: any) => {
    setLoading(true);

    try {
      const url = Backend.front_clients;
      const res = await axios.get(url, { params }).then((res) => res.data);

      if (res.code !== "success") {
        throw new Error(res.message);
      }

      setClients(res.data);
      setPagination(res.pagination);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteClient = async (id: string) => {
    try {
      const res = await axios
        .post(Backend.front_clients + "/" + id + "/delete")
        .then((res) => res.data);

      if (res.code !== "success") {
        throw new Error(res.message);
      }

      const newClients = clients.filter((client: any) => client._id !== id);
      setClients(newClients);

      setPagination(res.pagination);

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    clients,
    setClients,
    runQuery,
    pagination,
    deleteClient,
  };
};

export { useClient, useClients };

/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import SkyeModal from "../../components/SkyeModal";
import { storage } from "../../index";
import { toast } from "react-toastify";
import { helpers } from "../../helpers";
import AppContext from "../../AppContext";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import CircularLoader from "../../components/CircularLoader";
import ProductEditAttributeBox from "../../components/ProductEditAttributeBox";
import Currency from "../../components/Currency";
import ModalImageComponent from "../../components/components/ModalImage";
// import ReactStarsComponent from "../../components/components/ReactStars";
import { useAxiosGet } from "../../hooks/useAxios";
import Backend from "../../presets/backend";
import axios from "axios";

// import ProductEditAttributeBox from "../../components/ProductEditAttributeBox";

type Props = {
  title?: string;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  product?: any;
  setProduct: (product: any) => void;
  onModalUpdated: (product: any) => void;
};
const ProductModal = ({
  showModal,
  setShowModal,
  product,
  setProduct,
  onModalUpdated,
}: Props) => {
  const context: any = useContext(AppContext);

  const [productData, setProductData] = useState(product);

  const [productImages, setProductImages] = useState([] as any[]);

  const [selectedCategories, setSelectedCategories] = useState(
    (product.categories ?? []) as any[]
  );
  const [selectedAttributes, setSelectedAttributes] = useState([] as any[]);
  const [selectedAttributeDrop, setSelectedAttributeDrop] = useState(
    null as any
  ); //id

  const [showImage, setShowImage] = useState(false);
  const [image, setImage] = useState(null as any);

  useEffect(() => {
    setProductData(product);
    setSelectedCategories(product.categories ?? []);
    if (product.attributes && product.attributes.length > 0) {
      //add db_options to attributes
      let attrs: any = [];
      product.attributes.forEach((attribute: any) => {
        attrs.push({
          ...attribute,
          options:  attribute.options,
          db_options: attributes.find((attr: any) => attr._id === attribute.attribute_id)?.options,
        });
      });
      // console.log(attrs);
      
      setSelectedAttributes(attrs);
    } else {
      setSelectedAttributes([]);
    }
    setSelectedAttributeDrop(null);
    //load images to productImages
    if (product.images) {
      let images: any[] = [];
      product.images.forEach((image: any) => {
        images.push({
          image: image,
          file: null, //not a file
        });
      });
      setProductImages(images);
    } else {
      setProductImages([]);
    }
  }, [product, productData.attributes]);

  //get categories
  const { data: categories, loading: categoriesLoading } = useAxiosGet({
    url: Backend.categories,
    defaultData: [],
  });
  //get attributes
  const { data: attributes, loading: attributesLoading } = useAxiosGet({
    url: Backend.attributes,
    defaultData: [],
  });

  const inputChange = (e: any) => {
    setProductData({ ...productData, [e.target.name]: e.target.value });
  };
  const attributesDropChange = (e: any) => {
    setSelectedAttributeDrop(e.target.value); //id
  };

  const handleCategoriesChange = (value: any, checked: any) => {
    if (checked) {
      setSelectedCategories([...selectedCategories, value]);
    } else {
      setSelectedCategories(
        selectedCategories.filter((category: any) => category._id !== value._id)
      );
    }
  };

  const imageChange = (e: any, index: any = null) => {
    let images = [...productImages];
    if (index !== null && e.target.files[0]) {
      images[index].file = e.target.files[0];
    } else if (e.target.files[0]) {
      images.push({
        image: URL.createObjectURL(e.target.files[0]),
        file: e.target.files[0],
      });
    }
    setProductImages(images);
  };

  const deleteImage = (index: number) => {
    if (
      ![
        "admin",
        "super_admin",
        "production_manager",
        "inventory_manager",
      ].includes(context.user.role)
    ) {
      toast.error("You are not authorized to perform this action!");
      return;
    }

    let images = [...productImages];
    images.splice(index, 1);
    setProductImages(images);
  };

  const viewImage = (index: number) => {
    let images = [...productImages];
    setImage(images[index].image);
    setShowImage(true);
  };

  const addAttribute = () => {
    if (
      selectedAttributeDrop !== null &&
      selectedAttributeDrop !== "" &&
      selectedAttributeDrop !== "custom"
    ) {
      //add to productData

      //if attribute is already added to selectedAttributes don't add it again
      if (
        selectedAttributes.some(
          (productAttribute: any) =>
            productAttribute._id === selectedAttributeDrop
        )
      ) {
        return;
      }

      //get attribute in attributes where id = selectedAttributeDrop
      let attr = attributes.find(
        (attribute: any) => attribute._id === selectedAttributeDrop
      );
      if (attr) {
        attr.db_options = attr.options;
        //empty the options
        attr.options = [];
        //add to selectedAttributes
        setSelectedAttributes([...selectedAttributes, attr]);
      }
    } else if (selectedAttributeDrop === "custom") {
      //add custom attribute
      let attr = {
        name: "",
        options: [],
      };
      setSelectedAttributes([...selectedAttributes, attr]);
    }
  };

  const handleAttributeChange = (index: number, attribute: any) => {
    let attributes = [...selectedAttributes];
    attributes[index] = attribute;
    setSelectedAttributes(attributes);
  };

  const handleAttributeDelete = (index: number) => {
    //prompt user first
    if (window.confirm("Are you sure you want to delete this attribute?")) {
      let attributes = [...selectedAttributes];
      attributes.splice(index, 1);
      setSelectedAttributes(attributes);
    }
  };

  const onModalClosed = () => {
    //reset
    setProductData({});
    setSelectedCategories([]);
    setSelectedAttributeDrop(null);
    setProductImages([]);
  };

  const submitForm = async (e: any) => {
    e.preventDefault();

    if (
      ![
        "admin",
        "super_admin",
        "production_manager",
        "inventory_manager",
      ].includes(context.user.role)
    ) {
      toast.error("You are not authorized to perform this action!");
      return;
    }

    const loading = toast.loading("Please wait...");

    try {
      let formData: any = { ...productData };

      formData.status = formData.status || "publish";

      formData.categories = selectedCategories.map((category) => category._id);

      formData.attributes = selectedAttributes.map((attribute) => {
        return {
          attribute_id: attribute.attribute_id || attribute?._id,
          name: attribute.name,
          options: attribute.options?.map((option: any) => {
            return {
              label: option.label,
              value: option.value,
            };
          }),
        };
      });
      // console.log(formData.attributes);
      
      // formData.attributes.forEach((attribute: any) => { delete attribute.db_options; });

      //upload images first and get both met urls and new urls together as array[]
      let images: any[] = [];
      for (let i = 0; i < productImages.length; i++) {
        const image = productImages[i];
        if (image.file && image.file !== null) {
          //upload image
          let file = image.file;
          const imageName =
            formData.name +
            "-" +
            helpers.generateRandomString(10) +
            "." +
            file.name.split(".").pop();
          const imageRef = ref(storage, "images/products/" + imageName);
          await uploadBytes(imageRef, file)
            .then(async (snapshot) => {
              await getDownloadURL(imageRef).then((url) => {
                images.push(url);
              });
            })
            .catch((error) => {
              toast.error("Failed to upload an image");
            });
        } else {
          images.push(image.image);
        }
      }
      formData.images = images;

      //price to number
      formData.price = parseFloat(formData.price ?? 0);
      formData.regular_price = parseFloat(formData.regular_price ?? 0);
      formData.quantity = parseInt(formData.quantity ?? 0);
      formData.rating = parseInt(formData.rating ?? 0);

      if (productData._id) {
        //update
        delete formData._id;
        delete formData.doc_id;
        delete formData.user_id;
        delete formData.slug;
        delete formData.created_at;
        delete formData.updated_at;
        delete formData.__v;
        delete formData.search_keywords;

        const res = await axios
          .post(Backend.admin_products + "/" + productData._id, formData)
          .then((res) => res.data);

        if (res.code !== "success") {
          throw new Error(res.message || "An error occurred");
        }

        setProduct(res.data);
        setProductData(res.data);
        onModalUpdated(res.data);
        toast.success(res.message);
      } else {
        //create
        const res = await axios
          .post(Backend.admin_products, formData)
          .then((res) => res.data);

        if (res.code !== "success") {
          throw new Error(res.message || "An error occurred");
        }

        setProduct(res.data);
        setProductData(res.data);
        onModalUpdated(res.data);
        toast.success(res.message);
      }

      setShowModal(false);
      //reload the page ---------------- I'M TIRED OF RESOLVING CONTINUOUS RENDERING ISSUE
      window.location.reload();
    } catch (error: any) {
      console.log(error);
      toast.error(error.message || "An error occurred");
    } finally {
      toast.dismiss(loading);
    }
  };

  return (
    <>
      <SkyeModal
        title={productData._id ? "Product" : "New Product"}
        size="max-w-6xl"
        flex={false}
        showModal={showModal}
        setShowModal={setShowModal}
        onModalClosed={onModalClosed}
      >
        <form
          autoComplete="off"
          className="space-y-6"
          id="productData-edit-form"
          onSubmit={submitForm}
          method="POST"
          encType="multipart/form-data"
        >
          {/*body*/}
          <div className="relative p-6 flex-auto">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 grid grid-cols-6 gap-6">
                {/* PROPERTY SIDE 1 */}
                <div className="col-span-6 lg:col-span-3">
                  <div className="">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Product Name:
                    </label>
                    <input
                      name="name"
                      value={productData.name || ""}
                      onChange={inputChange}
                      required
                      type="text"
                      id="name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                      placeholder="Product Name"
                    />
                  </div>

                  <div className="my-4">
                    <label
                      htmlFor="description"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Descripton:
                    </label>
                    <textarea
                      name="description"
                      value={productData.description || ""}
                      onChange={inputChange}
                      rows={4}
                      required
                      id="description"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                      placeholder="About product"
                    ></textarea>
                  </div>

                  <div className="my-4">
                    <div className="grid grid-cols-2 gap-3">
                      <div>
                        <label
                          htmlFor="regular_price"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Regular Price:
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-gray-500 lg:text-sm font-bold">
                              <Currency />
                            </span>
                          </div>
                          <input
                            name="regular_price"
                            value={productData.regular_price || ""}
                            onChange={inputChange}
                            required
                            min={0}
                            type="number"
                            id="regular_price"
                            className="bg-gray-50 border p-2.5 pl-6 font-bold border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                            placeholder="0.00"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="price"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Sale Price:
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-gray-500 lg:text-sm font-bold">
                              <Currency />
                            </span>
                          </div>
                          <input
                            name="price"
                            value={productData.price || ""}
                            onChange={inputChange}
                            required
                            min={0}
                            type="number"
                            id="price"
                            className="bg-gray-50 border p-2.5 pl-6 font-bold border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                            placeholder="0.00"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* ATTRIBUTES */}
                  <div className="my-3 shadow py-4 pt-2 border border-gray-200 rounded-lg">
                    <div className="px-5">
                      {/* toggle input */}
                      <label className="relative inline-flex items-center cursor-pointer mb-2 mt-3">
                        <input
                          name="has_attributes"
                          checked={productData.has_attributes || false}
                          onChange={(e: any) =>
                            setProductData({
                              ...productData,
                              has_attributes: e.target.checked,
                            })
                          }
                          type="checkbox"
                          value=""
                          className="sr-only peer"
                        />
                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        <span className="ml-3 text-md font-bold text-gray-900 dark:text-gray-300">
                          Attribute (optional):
                        </span>
                      </label>
                    </div>
                    {productData.has_attributes && (
                      <div className="grid grid-cols-3 gap-3 items-end px-5 mt-2">
                        <div className="col-span-2">
                          {attributesLoading ? (
                            <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                              <CircularLoader
                                className={"w-full text-center"}
                              />
                            </div>
                          ) : (
                            <select
                              name='"attribute-drop'
                              value={selectedAttributeDrop || ""}
                              onChange={attributesDropChange}
                              id="attribute-drop"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            >
                              <option value="">Select attribute</option>
                              {/* <option value="custom">Custom product attribute</option> */}
                              {attributes.map(
                                (attribute: any, index: number) => {
                                  //if attribute is already added to selectedAttributes disable it
                                  let disabled = false;
                                  disabled = selectedAttributes.some(
                                    (productAttribute) =>
                                      productAttribute._id === attribute._id
                                  );
                                  return (
                                    <option
                                      key={index}
                                      value={attribute._id}
                                      disabled={disabled}
                                    >
                                      {attribute.name}
                                    </option>
                                  );
                                }
                              )}
                            </select>
                          )}
                        </div>
                        <div className="">
                          <button
                            onClick={addAttribute}
                            type="button"
                            className="w-full text-white bg-black hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center dark:bg-blue-600 dark:hover:bg-gray-700 dark:focus:ring-black"
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    )}

                    {/* divider */}
                    {productData.has_attributes &&
                      selectedAttributes.length > 0 && (
                        <div className="col-span-6 mt-2">
                          <hr className="my-1" />
                        </div>
                      )}
                    {/* attributes */}
                    <div className="">
                      {selectedAttributes.length > 0 &&
                      productData.has_attributes &&
                      attributes.length > 0
                        ? selectedAttributes.map((attr, index) => {
                            return (
                              <div key={index} className="px-3 my-3">
                                <ProductEditAttributeBox
                                  attribute={attr}
                                  index={index}
                                  handleAttributeChange={handleAttributeChange}
                                  handleAttributeDelete={handleAttributeDelete}
                                />
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                </div>

                {/* PROPERTY SIDE 2 */}
                <div className="col-span-6 lg:col-span-3">
                  <div className="shadow px-5 py-4 pt-2">
                    <label
                      htmlFor="name"
                      className="block text-sm font-bold text-gray-700"
                    >
                      Categories:
                    </label>

                    <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 items-center mt-2">
                      {categoriesLoading && (
                        <CircularLoader
                          className={"w-full text-center col-span-2"}
                        />
                      )}
                      {categories.map((category: any, index: number) => (
                        <div
                          key={index}
                          className="form-check form-check-inline inline-block"
                        >
                          <input
                            value={category}
                            checked={selectedCategories.some(
                              (selected) => selected._id === category._id
                            )}
                            onChange={(event) =>
                              handleCategoriesChange(
                                category,
                                event.target.checked
                              )
                            }
                            id={`category-${category._id}`}
                            className="form-check-input h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-black checked:border-black focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                            type="checkbox"
                          />
                          <label
                            className="text-sm form-check-label inline text-gray-800 cursor-pointer"
                            htmlFor={`category-${category._id}`}
                          >
                            {category.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="col-span-6 lg:col-span-3 mt-4 lg:mt-6">
                    <label
                      htmlFor="status"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Quantity:
                    </label>
                    <input
                      name="quantity"
                      value={productData.quantity || ""}
                      onChange={inputChange}
                      required
                      min={0}
                      type="text"
                      placeholder="Quantity"
                      id="quantity"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                    />
                  </div>

                  <div className="col-span-6 lg:col-span-3 mt-4 lg:mt-4">
                    <label
                      htmlFor="status"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Status:
                    </label>
                    <select
                      name="status"
                      value={productData.status || ""}
                      onChange={inputChange}
                      required
                      id="status"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                    >
                      <option value="publish">Publish</option>
                      <option value="draft">Draft</option>
                    </select>
                  </div>
                </div>

                {/* divider */}
                <div className="col-span-6">
                  <hr className="my-1" />
                </div>

                <h1 className="col-span-6 text-lg">Product images</h1>
                {/* horizontal rows */}
                <div className="col-span-6 flex overflow-x-scroll space-x-5">
                  {/* images */}
                  {productImages.map((productImage, index) => {
                    let image = productImage.image;
                    if (productImage.file) {
                      image = URL.createObjectURL(productImage.file);
                    }
                    return (
                      <div
                        key={index}
                        className="flex-shrink-0 w-[200px] h-[200px] relative"
                      >
                        <img
                          src={image}
                          alt=""
                          className="w-full h-full object-cover rounded-md cursor-pointer"
                          onClick={() => viewImage(index)}
                        />
                        {/* delete button at right corner */}
                        <button
                          onClick={() => deleteImage(index)}
                          type="button"
                          className="absolute top-1 right-1 w-8 h-8 bg-red-500 rounded-full flex items-center justify-center text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        >
                          <span className="sr-only">Delete</span>
                          <svg
                            className="w-5 h-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.293 5.293a1 1 0 011.414 0L10 8.586l3.293-3.293a1 1 0 111.414 1.414L11.414 10l3.293 3.293a1 1 0 01-1.414 1.414L10 11.414l-3.293 3.293a1 1 0 01-1.414-1.414L8.586 10 5.293 6.707a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </div>
                    );
                  })}
                  {/* picker */}
                  <div className="flex-shrink-0 w-[200px] h-[200px]">
                    <label className="flex justify-center w-full h-full px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
                      <span className="flex items-center space-x-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon w-12 h-12 icon-tabler icon-tabler-camera"
                          width="48"
                          height="48"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="#597e8d"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M5 7h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2" />
                          <path d="M9 13a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                        </svg>
                      </span>
                      {/* ony image files */}
                      <input
                        type="file"
                        name="image_file"
                        className="hidden"
                        accept="image/*"
                        onChange={(e: any) => imageChange(e)}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*footer*/}
          {[
            "admin",
            "super_admin",
            "production_manager",
            "inventory_manager",
          ].includes(context.user.role) && (
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                type="submit"
                className="w-full text-white bg-black hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-4 text-center dark:bg-blue-600 dark:hover:bg-gray-700 dark:focus:ring-black"
              >
                <span id="submit-text">
                  {productData._id ? "UPDATE" : "SUBMIT"}
                </span>
              </button>
            </div>
          )}
        </form>
      </SkyeModal>

      {/* image modal */}
      {showImage ? (
        <ModalImageComponent
          medium={image}
          small={image}
          large={image}
          alt={productData.name}
          onClose={() => setShowImage(false)}
        />
      ) : null}
    </>
  );
};

export default ProductModal;

/* eslint-disable eqeqeq */
import { useImperativeHandle, useRef, useState } from "react";
import { usePayment } from "../hooks/usePayments";
import { Shimmer } from "react-shimmer";
import { Button, IconButton } from "@mui/material";
import { helpers } from "../../helpers";
import StatusLabel from "../../components/StatusLabel";
import { toBlob } from "html-to-image";
import { Close } from "@mui/icons-material";
// import jsPDF from 'jspdf';
import { toast } from "react-toastify";
import generatePDF, { Resolution } from "react-to-pdf";
import { useSmartLoadingDialogContext } from "./SmartLoadingDialog";

type InvoiceDialogProps = {
  refx: any;
  done?: () => void;
};
export const InvoiceDialog = ({ refx, done }: InvoiceDialogProps) => {
  useImperativeHandle(
    refx,
    () =>
      ({
        open,
      } as any)
  );

  const smartLoading = useSmartLoadingDialogContext();

  const [show, setShow] = useState(false);

  const imageRef = useRef(null);

  const [creatingInvoice, setCreatingInvoice] = useState(false);

  const { payment, loading, runQuery } = usePayment({
    id: "",
    initialize: false,
  });

  const open = (paymentId: string) => {
    setShow(true);
    runQuery(paymentId);
  };

  const shareInvoiceAsImage = async () => {
    setCreatingInvoice(false);
    await new Promise((resolve) => setTimeout(resolve, 1000)); //wait setCreatingInvoice to be false
    const node = imageRef.current;
    if (!node) {
      // toast.error("Failed to share invoice");
      return false;
    }
    const newFile = await toBlob(node);
    if (!newFile) {
      // toast.error("Failed to share invoice");
      return false;
    }

    //download the image
    const url = URL.createObjectURL(newFile);
    const a = document.createElement("a");
    a.href = url;
    a.download = `invoice-${payment?._id}.png`;
    a.click();

    const data = {
      files: [
        new File([newFile], `invoice-${payment?._id}.png`, {
          type: newFile.type,
        }),
      ],
      title: `Invoice #${payment?._id}`,
      text: `Invoice #${payment?._id}`,
    };
    try {
      if (!navigator.canShare(data)) {
        // toast.error("Sharing not supported");
        throw new Error("Sharing not supported");
      }
      await navigator.share(data);
      return true;
    } catch (err) {
      // console.log(err);
      // toast.error("Failed to share invoice");
      return false;
    }
  };

  const shareInvoice = async () => {
    // ?##################### below is the html to pdf - one ############################
    const loading = toast.loading("Generating invoice...", {
      closeOnClick: true,
    });
    setCreatingInvoice(true);
    smartLoading.show();
    await new Promise((resolve) => setTimeout(resolve, 1000)); //wait setCreatingInvoice to be true
    
    var pdfBuild = await generatePDF(imageRef, {
      method: "build",
      resolution: Resolution.HIGH,
      page: {
        // format: [(imageRef.current! as any).clientWidth + 1, (imageRef.current! as any).clientHeight + 20],
        // format: [200, 200],
        orientation: "portrait",
      },
      canvas: {
        mimeType: "image/png",
        qualityRatio: 1,
      },
      filename: `invoice-${payment?._id}.pdf`,
      overrides: {
        pdf: {
          //default format is A4
          // format: [
          //   // ((imageRef.current! as any).clientWidth + 1) / 4,
          //   // ((imageRef.current! as any).clientHeight + 20) / 4,
          // ],
          orientation: "portrait",
          unit: "px",
          compress: false,
          userUnit: 1,
        },
      },
    });
    smartLoading.dismiss();
    setCreatingInvoice(false);

    // //share the built pdf
    const file = new File(
      [pdfBuild.output("blob")],
      `invoice-${payment?._id}.pdf`,
      {
        type: "application/pdf",
      }
    );

    //download the pdf
    const url = URL.createObjectURL(file);
    const a = document.createElement("a");
    a.href = url;
    a.download = `invoice-${payment?._id}.pdf`;
    a.click();

    const data = {
      files: [file],
      title: `Invoice #${payment?._id}`,
      text: `Invoice #${payment?._id}`,
    };

    try {
      if (!navigator.canShare(data)) {
        toast.error("Sharing not supported");
      }
      await navigator.share(data);
    } catch (err) {
      // console.log(err);
      // toast.error("Failed to share invoice");
      //open the saved file
      try {
        if (await shareInvoiceAsImage()) {
          return;
        } else {
          window.open(URL.createObjectURL(file));
        }
      } catch (err) {
        window.open(URL.createObjectURL(file));
      }
    }

    toast.update(loading, {
      render: "Invoice generated",
      type: "success",
      isLoading: false,
      autoClose: 1000,
    });

    // ?##################### below is the html to pdf - two ############################
    // setCreatingInvoice(true);
    // const doc = new jsPDF({
    //   //get the height and width imageRef.current
    // 	format: [(imageRef.current! as any).clientWidth + 1, (imageRef.current! as any).clientHeight + 20],
    // 	unit: 'px',
    // });

    // // Adding fonts (Montserrat)
    // // doc.addFileToVFS('Montserrat-Regular.ttf', helpers.fonts.montserratRegular);

    // const loading = toast.loading("Generating invoice...");

    // await doc.html(imageRef.current!, {
    // 	async callback(doc) {
    // 		//  doc.save(`invoice-${payment?._id}.pdf`);
    //     //share the invoice as pdf

    //     const file = await doc.output('blob');

    //     const data = {
    //       files: [
    //         new File([file], `invoice-${payment?._id}.pdf`, {
    //           type: "application/pdf",
    //         }),
    //       ],
    //       title: `Invoice #${payment?._id}`,
    //       text: `Invoice #${payment?._id}`,
    //     };

    //     try {
    //       if (!navigator.canShare(data)) {
    //         toast.error("Sharing not supported");
    //       }
    //       await navigator.share(data);
    //     } catch (err) {
    //       // console.log(err);
    //       // toast.error("Failed to share invoice");
    //       const f = new File([file], `invoice-${payment?._id}.pdf`, {
    //         type: "application/pdf",
    //       });
    //       //open the saved file
    //    try {
    //      shareInvoiceAsImage();
    //    } catch (err) {
    //    window.open(URL.createObjectURL(f));
    //  }
    //     }

    // 	},
    // });

    // //update the toast
    // toast.update(loading, {
    //   render: "Invoice generated",
    //   type: "success",
    //   isLoading: false,
    //   autoClose: 3000,
    // });
    // setCreatingInvoice(false);
  };

  const handleChildClick = (e: any) => {
    e.stopPropagation();
  };

  return (
    <>
      <div
        className={`fixed left-0 top-0 h-screen z-50 bg-[#000000c0] w-full flex items-center justify-center overflow-auto ${
          !show && "hidden"
        }`}
        onClick={() => {
          setShow(false);
          done && done();
        }}
      >
        <IconButton
          aria-label="close"
          color="primary"
          className="!absolute !top-5 !right-5 !z-50 !bg-white !shadow-lg !drop-shadow-md !ml-3"
          onClick={() => {
            setShow(false);
            done && done();
          }}
        >
          <Close className="!text-black !text-2xl !font-bold" />
        </IconButton>
        {loading ? (
          <PageShimmer done={done} setShow={setShow} />
        ) : (
          <div className={`h-full py-4 overflow-auto xw-full mx-2 sm:w-auto sm:mx-auto ${creatingInvoice && "!w-[800px]"}`}>
            <div
              onClick={handleChildClick}
              className={`flex flex-col justify-center items-center gap-y-3 text-sm text-gray-700 bg-white rounded-sm w-full ${creatingInvoice && "!w-[800px]"}`}
            >
              <div className="w-full px-5 mt-2">
                <div className="flex flex-row items-end justify-end w-full border-b border-b-gray-300 pb-3">
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    className="!px-4 !rounded-md !normal-case !text-lg !h-[35px] !mt-1"
                    onClick={async () => {
                      shareInvoiceAsImage();
                      // shareInvoice();
                    }}
                  >
                    <span>Send</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 ml-3"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                      />
                    </svg>
                  </Button>
                  <div className="w-16"></div>
                </div>
              </div>
              {/* screenshot */}
              <div
                ref={imageRef}
                className="flex flex-col justify-center items-center gap-y-3 text-sm text-gray-700 bg-white rounded-sm pt-3 w-full font-montserrat"
              >
                <div className="flex flex-col justify-center items-center gap-y-3 text-sm text-gray-700 bg-white rounded-sm pt-3 w-full px-4">
                  {/* invoice info */}
                  <div className="w-full flex flex-col gap-y-2 justify-center">
                    <div className="flex flex-row items-center justify-between">
                      <img
                        src="/assets/icons/dipsy-icon.png"
                        alt="logo"
                        className="w-14 h-14"
                      />
                      <div className="font-semibold text-3xl">INVOICE</div>
                    </div>
                    <div className="flex flex-row items-center justify-between gap-x-3 text-[16px] mt-2">
                      <div className="text-gray-400">Invoice ID</div>
                      <div className="text-black tracking-widest">
                        DIP{payment?._id}
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-between gap-x-3 text-[16px]">
                      <div className="text-gray-400">Invoice Date</div>
                      <div className="text-black">
                        {new Date(payment?.created_at).toLocaleDateString()}
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-between gap-x-3 text-[16px]">
                      <div className="text-gray-400">Client</div>
                      <div className="text-black">
                        {payment?.client_full_name ||
                          payment?.client?.full_name}
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-between gap-x-3 text-[16px]">
                      <div className="text-gray-400">Address</div>
                      <div className="text-black">
                        {payment?.client?.address ||
                          payment?.custom?.client?.address ||
                          "N/A"}
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-between gap-x-3 text-[16px]">
                      <div className="text-gray-400">Payment Status</div>
                      <div className="text-black">
                        <StatusLabel
                          status={payment?.status}
                          className={`${
                            creatingInvoice &&
                            "!flex !flex-row !items-center !justify-center h-6 !pb-4 !pt-0 !mb-[-10px]"
                          }`}
                          replacements={{
                            processing: "Outstanding",
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-between gap-x-3 text-[16px] mt-1">
                      <div className="text-gray-400 flex items-center justify-center">
                        Order Status
                      </div>
                      <div className="text-black flex items-center justify-center">
                        <StatusLabel
                          status={payment.order?.status ?? payment.status}
                          className={`${
                            creatingInvoice &&
                            "!flex !flex-row !items-center !justify-center h-6 !pb-4 !pt-0 !mb-[-10px]"
                          }`}
                        />
                      </div>
                    </div>
                  </div>
                  {/* items */}
                  {/* <div className="w-full flex flex-col gap-y-2 justify-center border-t border-t-gray-800 pt-2">
                  <div className="font-bold text-lg">Items</div>
                  {payment.order?.items?.map((item: any) => {
                    return (
                      <div
                        key={
                          item.product_id +
                          (item.product?.name || item.custom?.name)
                        }
                        className="flex flex-row items-center justify-between gap-x-3 text-[16px]"
                      >
                        <img
                          className="ImgEcheveria w-8 h-8  rounded-lg object-cover"
                          src={
                            item.type == "custom" || !item.product_id
                              ? "/assets/images/custom_product.png"
                              : helpers.getFirstImage(
                                  item.product?.images,
                                  "product"
                                )
                          }
                          alt="Product"
                        />
                        <div className="text-gray-400 text-left w-full">
                          x{item.quantity} -{" "}
                          {item.product?.name || item.custom?.name}
                        </div>
                        <div className="text-black font-bold">
                          N{helpers.money(item.subtotal)}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="flex flex-row items-center justify-between gap-x-3 text-[16px] w-full border-t border-t-gray-800 pt-2 mt-2">
                  <div className="text-gray-400 text-lg">Total</div>
                  <div className="text-black font-bold text-xl">
                    N{helpers.money(payment.amount)}
                  </div>
                </div> */}
                  {/* using table for items */}
                  <div className="relative overflow-x-auto">
                    <table className={`w-full  min-w-[calc(350px)] ${creatingInvoice && "!w-[800px]"}`}>
                      <thead
                        className={`bg-gray-100 border-b border-t border-gray-300 uppercase font-normal ${
                          creatingInvoice && ""
                        }`}
                      >
                        <tr>
                          <th
                            className={`text-left ${
                              creatingInvoice && "!pb-3 !pt-0"
                            }`}
                          ></th>
                          <th
                            className={`text-left ${
                              creatingInvoice && "!pb-3 !pt-0"
                            }`}
                          >
                            Product
                          </th>
                          <th
                            className={`text-center ${
                              creatingInvoice && "!pb-3 !pt-0"
                            }`}
                          >
                            QTY
                          </th>
                          <th
                            className={`text-left pl-4 ${
                              creatingInvoice && "!pb-3 !pt-0"
                            }`}
                          >
                            Price
                          </th>
                          <th
                            className={`text-left pl-4 ${
                              creatingInvoice && "!pb-3 !pt-0"
                            }`}
                          >
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody className="w-full">
                        {payment.order?.items?.map((item: any) => {
                          return (
                            <tr
                              key={item.product_id}
                              className="bg-white border-b w-full"
                            >
                              <td
                                className={`text-left ${
                                  creatingInvoice && "!pb-3 !pt-0"
                                }`}
                              >
                                <img
                                  className="ImgEcheveria w-8 h-8  rounded-lg object-cover"
                                  src={
                                    item.type == "custom" || !item.product_id
                                      ? "/assets/images/custom_product.png"
                                      : helpers.getFirstImage(
                                          item.product?.images,
                                          "product"
                                        )
                                  }
                                  alt="Product"
                                />
                              </td>
                              <td
                                className={`text-left ${
                                  creatingInvoice && "!pb-3 !pt-0"
                                }`}
                              >
                                <span className="text-xs">
                                  {item.product?.name || item.custom?.name}
                                </span>
                              </td>
                              <td
                                className={`text-center ${
                                  creatingInvoice && "!pb-3 !pt-0"
                                }`}
                              >
                                {item.quantity}
                              </td>
                              <td
                                className={`text-left pl-4 ${
                                  creatingInvoice && "!pb-3 !pt-0"
                                }`}
                              >
                                N{helpers.money(item.price)}
                              </td>
                              <td
                                className={`text-left pl-4 ${
                                  creatingInvoice && "!pb-3 !pt-0"
                                }`}
                              >
                                N{helpers.money(item.subtotal)}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td
                            colSpan={4}
                            className={`text-right font-bold ${
                              creatingInvoice && "!pb-3 !pt-0"
                            }`}
                          >
                            Total
                          </td>
                          <td
                            className={`text-left pl-4 font-bold ${
                              creatingInvoice && "!pb-3 !pt-0"
                            }`}
                          >
                            N{helpers.money(payment.amount)}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                {/* bank */}
                <div className="flex flex-col gap-y-2 justify-center mt-1 w-full bg-gray-100 p-3 text-sm">
                  <div className="flex flex-row items-center justify-between gap-x-3 w-full">
                    <div className="text-gray-400 whitespace-nowrap">Bank</div>
                    <div className="text-black w-full text-right font-semibold tracking-wide">
                      LOTUS Bank
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-between gap-x-3 w-full">
                    <div className="text-gray-400 whitespace-nowrap">
                      Acc Name
                    </div>
                    <div className="text-black w-full text-right font-semibold tracking-wide">
                      DIPSY FIBERGLASS <br /> MANUFACTURING LIMITED
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-between gap-x-3  w-full">
                    <div className="text-gray-400 whitespace-nowrap">
                      Acc No
                    </div>
                    <div className="text-black w-full text-right font-semibold tracking-wide">
                      1000709034
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

type PageShimmerProps = {
  done?: () => void;
  setShow?: (show: boolean) => void;
};
const PageShimmer = ({ done, setShow }: PageShimmerProps) => {
  return (
    <>
      <div className="flex flex-col gap-y-3 text-sm text-gray-700 bg-white rounded-xl p-5 w-full mx-5 sm:w-auto sm:mx-auto sm:min-w-[300px]">
        <div className="flex flex-row items-end justify-end">
          <Shimmer height={20} width={100} className="!w-[100px]" />
          <IconButton
            aria-label="cart"
            color="primary"
            className="!bg-white !shadow-lg !drop-shadow-md !ml-3"
            onClick={() => {
              setShow && setShow(false);
              done && done();
            }}
          >
            <Close className="!text-black !font-bold" />
          </IconButton>
        </div>
        <Shimmer height={20} width={100} className="!w-[100px]" />
        <Shimmer height={30} width={300} className="!w-full" />
        <Shimmer height={100} width={500} className="!w-full" />
        <Shimmer height={20} width={100} className="!w-[100px]" />
        <Shimmer height={30} width={300} className="!w-full" />
        <Shimmer height={30} width={500} className="!w-full" />
        <Shimmer height={30} width={500} className="!w-full" />
      </div>
    </>
  );
};

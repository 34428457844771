/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useRef, useState } from "react";
import OrdersTable from "../components/OrdersTable";
import useDocumentTitle from "../hooks/userDocumentTitle";
import MainTemplate from "./MainTemplate";
import { helpers } from "../helpers";
import Currency from "../components/Currency";
import ExportExcelButton from "../components/Buttons/ExportExcelButton";
import AppContext from "../AppContext";
import { useAxiosGet } from "../hooks/useAxios";
import Backend from "../presets/backend";

import { collection, getDocs, query } from "firebase/firestore";
import { db } from "..";
import { toast } from "react-toastify";

const Orders = () => {
  useDocumentTitle("Orders");

  const context: any = useContext(AppContext);

  const orderModalRef = useRef(null as any);

  const [totalSales, setTotalSales] = useState(0);
  const [totalPending, setTotalPending] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);

  const [totalSalesAmount, setTotalSalesAmount] = useState(0.0);
  const [totalPendingAmount, setTotalPendingAmount] = useState(0.0);
  const [totalPaidAmount, setTotalPaidAmount] = useState(0.0);

  //get id from url
  const urlParams = new URLSearchParams(window.location.search);
  const getId = urlParams.get("id");

  //get orders
  const {
    data: orders,
    loading: ordersLoading,
    ...ordersRest
  } = useAxiosGet({
    url: getId ? `${Backend.orders}?id=${getId}` : Backend.orders,
    defaultData: [],
    onReady: (response) => {
      //update totals (stats)
      var totalSales = 0;
      var totalPending = 0;
      var totalPaid = 0;

      var totalSalesAmount = 0.0;
      var totalPendingAmount = 0.0;
      var totalPaidAmount = 0.0;

      response.data?.forEach((order: any) => {
        if (order.payment_status === "paid") {
          totalPaid++;
          totalPaidAmount += order.total;
        } else if (order.payment_status === "pending") {
          totalPending++;
          totalPendingAmount += order.total;
        }

        totalSales++;
        totalSalesAmount += order.total;
      });

      setTotalSales(totalSales);
      setTotalPending(totalPending);
      setTotalPaid(totalPaid);

      setTotalSalesAmount(totalSalesAmount);
      setTotalPendingAmount(totalPendingAmount);
      setTotalPaidAmount(totalPaidAmount);

    }
  });

  //get sales managers
  const {
    data: salesManagers
  } = useAxiosGet({
    url: `${Backend.users}?role=sales_manager`,
    defaultData: [],
  });

  var params: any = {};

  const fetchOrders = async (
    name = "",
    fromDate = "",
    toDate = "",
    id = "",
    salesManager = "",
  ) => {

    if (name && name.length > 0) {
      params.search = name;
    } else if (fromDate && fromDate.length > 0 && toDate && toDate.length > 0) {
      params.from = fromDate;
      params.to = toDate;
    } else if (id && id.length > 0) {
      params.id = id;
    } else if (salesManager && salesManager.length > 0) {
      params.sales_manager_id = salesManager;
    }

    ordersRest.run(Backend.orders + "?" + new URLSearchParams(params).toString());

  };

  const onNextPage = () => {
      const next = parseInt(ordersRest.responsdeData?.pagination?.current_page) + 1;
      ordersRest.run(Backend.orders + "?" + new URLSearchParams({ ...params, page: next }).toString());
  }

  const onPrevPage = () => {
      const prev = parseInt(ordersRest.responsdeData?.pagination?.current_page) - 1;
      ordersRest.run(Backend.orders + "?" + new URLSearchParams({ ...params, page: prev }).toString());
  }


  const onSearchNameButtonClicked = () => {
    (document.getElementById("from_date") as any).value = "";
    (document.getElementById("to_date") as any).value = "";
    (document.getElementById("search_id") as any).value = "";
    (document.getElementById("sales_manager") as any).value = "";
    const name: any = (document.getElementById("product_name") as any)?.value;
    fetchOrders(name);
  };

  const onSearchDateButtonClicked = () => {
    (document.getElementById("product_name") as any).value = "";
    (document.getElementById("search_id") as any).value = "";
    (document.getElementById("sales_manager") as any).value = "";
    const fromDate: any = (document.getElementById("from_date") as any)?.value;
    const toDate: any = (document.getElementById("to_date") as any)?.value;
    fetchOrders("", fromDate, toDate, "");
  };

  const onSearchIDButtonClicked = () => {
    (document.getElementById("product_name") as any).value = "";
    (document.getElementById("from_date") as any).value = "";
    (document.getElementById("to_date") as any).value = "";
    (document.getElementById("sales_manager") as any).value = "";
    const id: any = (document.getElementById("search_id") as any)?.value;
    fetchOrders("", "", "", id);
  };

  const onSalesManagerChanged = () => {
    (document.getElementById("product_name") as any).value = "";
    (document.getElementById("from_date") as any).value = "";
    (document.getElementById("to_date") as any).value = "";
    (document.getElementById("search_id") as any).value = "";
    const salesManager: any = (document.getElementById("sales_manager") as any)?.value;
    fetchOrders("", "", "", "", salesManager);
  }

  const onTableUpdated = (order: any) => {
    fetchOrders();
  };

  const exportFirebaseToJson = async () => {
    const loading = toast.loading("Exporting docs to JSON file...");

    try {
      let docs: any[] = [];
      const docsRef = collection(db, "orders");
      const q = query(docsRef);

      await getDocs(q).then((snapshot) => {
        snapshot.forEach((doc) => {
          docs.push({ ...doc.data(), id: doc.id });
        });
      });

      const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
        JSON.stringify(docs)
      )}`;
      const link = document.createElement("a");
      link.href = jsonString;
      link.download = "dipsy_orders.json";

      link.click();
    } catch (error: any) {
      console.log(error);
      toast.error("Failed to export docs to JSON file");
    } finally {
      toast.dismiss(loading);
    }
  };

  return (
    <>
      <MainTemplate title="Orders">
        {/* <!-- search filter --> */}
        <div className="flex flex-col lg:flex-row items-start lg:items-center justify-start lg:justify-center w-full gap-4">
          <div className="flex flex-row w-full lg:w-auto">
            <input
              type="search"
              id="product_name"
              onKeyDown={(e: any) => {
                if (e.key === "Enter") {
                  onSearchNameButtonClicked();
                }
              }}
              className="block p-2.5 w-full  text-sm text-gray-900 bg-gray-50 rounded-l-lg appearance-none border border-gray-300 focus:ring-black focus:border-black dark:bg-gray-700 dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-black"
              placeholder="Search name"
            />
            <button
              onClick={onSearchNameButtonClicked}
              type="button"
              className="p-2.5 text-sm font-medium text-white bg-black rounded-r-lg border border-black hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-black dark:hover:bg-gray-900 dark:focus:ring-blue-800"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
              <span className="sr-only">Search</span>
            </button>
          </div>
          <div className="flex flex-row w-full lg:w-auto">
            <input
              type="search"
              id="search_id"
              onKeyDown={(e: any) => {
                if (e.key === "Enter") {
                  onSearchIDButtonClicked();
                }
              }}
              className="block p-2.5 w-full  text-sm text-gray-900 bg-gray-50 rounded-l-lg appearance-none border border-gray-300 focus:ring-black focus:border-black dark:bg-gray-700 dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-black"
              placeholder="Search ID"
            />
            <button
              onClick={onSearchIDButtonClicked}
              type="button"
              className="p-2.5 text-sm font-medium text-white bg-black rounded-r-lg border border-black hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-black dark:hover:bg-gray-900 dark:focus:ring-blue-800"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
              <span className="sr-only">Search</span>
            </button>
          </div>
          <div className="flex flex-row w-full lg:w-auto">
            <input
              type="date"
              id="from_date"
              className="block p-2.5 w-full  text-sm text-gray-900 bg-gray-50 rounded-tl-lg appearance-none rounded-bl-lg border border-r-0 border-gray-300 focus:ring-black focus:border-black dark:bg-gray-700 dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-black"
              placeholder="From"
            />
            <input
              type="date"
              id="to_date"
              className="block p-2.5 w-full  text-sm text-gray-900 bg-gray-50 border appearance-none border-gray-300 focus:ring-black focus:border-black dark:bg-gray-700 dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-black"
              placeholder="To"
            />
            <button
              onClick={onSearchDateButtonClicked}
              type="button"
              className="p-2.5 text-sm font-medium text-white bg-black rounded-r-lg border border-black hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-black dark:hover:bg-gray-900 dark:focus:ring-blue-800"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
              <span className="sr-only">Search</span>
            </button>
          </div>
          <div className="flex flex-row w-full lg:w-auto">
            <select
              id="sales_manager"
              onChange={onSalesManagerChanged}
              className="block p-2.5 min-w-[180px] w-full  text-sm text-gray-900 bg-gray-50 rounded-l-lg appearance-none border border-gray-300 focus:ring-black focus:border-black dark:bg-gray-700 dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-black">
              <option value="">Sales Manager</option>
              {
                salesManagers.map((salesManager: any) => (
                  <option key={salesManager._id} value={salesManager._id}>{salesManager.full_name}</option>
                ))
              }
              </select>
              <button
              type="button"
              onClick={() => {
                //open select dropdown
                var selectElement = document.getElementById("sales_manager") as any;

                if (document.createEvent) {
                  var e = document.createEvent("MouseEvents");
                  e.initEvent("mousedown", true, true);
                  selectElement.dispatchEvent(e);
                } else if (selectElement.fireEvent) {
                  selectElement.fireEvent("onmousedown");
                }
              }}
              className="p-2 text-sm font-medium text-white bg-black rounded-r-lg border border-black">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
              </svg>

            </button>
          </div>
          <div className="hidden lg:flex flex-row w-full lg:w-auto">
            <ExportExcelButton
              excelData={orders.map((order: any) => {
                return {
                  "ID": order._id,
                  "Sales Manager": order.sales_manager?.full_name,
                  Amount: order.total,
                  "Outstanding": (() => {
                    var totalPaid = parseFloat(order.payment?.total_paid);
                    var totalAmount = parseFloat(order.payment?.amount);
                    if (totalPaid < totalAmount && (order.payment?.status !== "paid" || order.payment?.status !== "completed")) {
                      return totalAmount - totalPaid;
                    }
                    return 0;
                  })(),
                  Client: order.client?.full_name || order.client_full_name || order.custom?.client?.full_name,
                  "Client Status": order.is_new_client ? "New" : "Old",
                  Email: order.client?.email || order.custom?.client?.email,
                  Phone: order.client?.phone || order.custom?.client?.phone,
                  "Payment Status": (order.payment?.status || "N/A").replace("processing", "outstanding").replace("Processing", "Outstanding"),
                  "Order Status": order.status,
                  Date: (new Date(order.created_at)).toLocaleDateString(),
                };
              })}
              fileName={`Orders-${new Date().toLocaleDateString()}`}
              className="w-full text-white mr-2 bg-black py-1.5 ml-4 text-lg px-5 rounded hover:bg-blue-600 flex flex-row items-center justify-center"
              data-modal-toggle="add-modal"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="inline h-6 w-6 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                />
              </svg>
              Export
            </ExportExcelButton>
          </div>
        </div>

        {/* stats */}
        {ordersLoading ? (
          <></>
        ) : (
          <>
          {/* only admin, super_admin, accounting_manager can see this */}
          {
              (['admin', 'super_admin', 'accounting_manager'].includes(context.user.role)) && (
                <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-2 mt-2">
                  <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all w-full">
                    <div className="bg-white p-4">
                      <div className="lg:flex lg:items-start">
                        <div className="text-center lg:mt-0 lg:ml-2 lg:text-left">
                          <h3 className="text-sm leading-6 font-medium text-gray-400">
                            Total Sales ({totalSales})
                          </h3>
                          <p className="text-xl font-bold text-black">
                            <Currency />
                            {helpers.money(totalSalesAmount)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all w-full">
                    <div className="bg-white p-4">
                      <div className="lg:flex lg:items-start">
                        <div className="text-center lg:mt-0 lg:ml-2 lg:text-left">
                          <h3 className="text-sm leading-6 font-medium text-gray-400">
                            Total Paid ({totalPaid}/{totalSales})
                          </h3>
                          <p className="text-xl font-bold text-black">
                            <Currency />
                            {helpers.money(totalPaidAmount)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all w-full">
                    <div className="bg-white p-4">
                      <div className="lg:flex lg:items-start">
                        <div className="text-center lg:mt-0 lg:ml-2 lg:text-left">
                          <h3 className="text-sm leading-6 font-medium text-gray-400">
                            Total Pending ({totalPending}/{totalSales})
                          </h3>
                          <p className="text-xl font-bold text-black">
                            <Currency />
                            {helpers.money(totalPendingAmount)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          </>
        )}

        <OrdersTable
          orders={orders}
          pagination={ordersRest.responsdeData?.pagination}
          onNextPage={onNextPage}
          onPrevPage={onPrevPage}
          loading={ordersLoading}
          onTableUpdated={onTableUpdated}
          refx={orderModalRef}
        />

         {/* export button */}
      <div className="xflex flex-row w-full items-center justify-center hidden">
        <button
          onClick={exportFirebaseToJson}
          type="button"
          className="p-2.5 text-sm font-medium text-white bg-black rounded-lg border border-black hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-black dark:hover:bg-gray-900 dark:focus:ring-blue-800"
        >
          Export Docs to JSON
        </button>
      </div>

      </MainTemplate>
    </>
  );
};

export default Orders;

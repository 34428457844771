/* eslint-disable eqeqeq */
import { useContext, useEffect, useState } from "react";
import SkyeModal from "../../components/SkyeModal";
import Currency from "../../components/Currency";
import { toast } from "react-toastify";
import AppContext from "../../AppContext";
import { helpers } from "../../helpers";
import StatusLabel from "../../components/StatusLabel";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Backend from "../../presets/backend";

type Props = {
  title?: string;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  order?: any;
  setOrder: (order: any) => void;
  onModalUpdated: (order: any) => void;
};
const OrderModal = ({
  showModal,
  setShowModal,
  order,
  setOrder,
  onModalUpdated,
}: Props) => {
  const navigate = useNavigate();

  const context: any = useContext(AppContext);

  const [orderData, setOrderData] = useState(order ?? {});
  const [notes, setNotes] = useState(orderData.notes ?? []);

  useEffect(() => {
    setOrderData(order);
    setNotes(orderData.notes ?? []);
  }, [order, orderData.notes]);

  const onInputChanged = (e: any) => {
    setOrderData({ ...orderData, [e.target.name]: e.target.value });
  };

  const onFormSubmit = async (e: any) => {
    e.preventDefault();

    const loading = toast.loading("Updating order...");

    //remove other fields except status and notes
    let formData: any = {
      status: orderData.status,
      notes: notes,
    };

    try {
      const res = await axios
        .post(`${Backend.orders}/${orderData._id}`, formData)
        .then((res) => res.data);

      if (res.code !== "success") {
        throw new Error(res.message || "An error occurred. Please try again.");
      }

      toast.success(res.message || "Order updated successfully.");

      setOrder({});
      onModalUpdated(res.data);
      setShowModal(false);
    } catch (error: any) {
      toast.error(
        error.response?.data?.message || error.message || "An error occurred"
      );
    } finally {
      toast.dismiss(loading);
    }
  };

  return (
    <>
      <SkyeModal
        title={orderData._id ? `Order #${orderData._id}` : "New Order"}
        size="max-w-2xl"
        flex={false}
        showModal={showModal}
        setShowModal={() => {
          setShowModal(false);
          setOrder({});
        }}
      >
        <form
          className="space-y-6"
          id="order-edit-form"
          onSubmit={onFormSubmit}
          method="POST"
          encType="multipart/form-data"
        >
          {/*body*/}
          <div className="relative p-6 flex-auto">
            {/* body */}

            {/* date */}
            <div className="col-span-6 lg:col-span-3 mb-4">
              <div className="grid grid-cols-2 items-center">
                <div className="block text-sm font-bold text-black">Date:</div>
                <div className="font-bold">
                  {new Date(orderData?.created_at)?.toLocaleDateString()}
                </div>
              </div>
            </div>

            {/* delivery date with bell icon */}
            <div className="col-span-6 lg:col-span-3 mb-4 border border-gray-500 rounded-lg p-1 border-dashed">
              <div className="grid grid-cols-2 items-center">
                <div className="block text-sm font-bold text-black">
                  Delivery Date:
                </div>
                <div className="font-bold">
                  {orderData.delivery_date ? (
                    <div className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6 w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0"
                        />
                      </svg>

                      <span className="ml-1">
                        {new Date(orderData.delivery_date).toLocaleDateString()}
                      </span>
                    </div>
                  ) : (
                    "N/A"
                  )}
                </div>
              </div>
            </div>

            {/* status */}
            <div className="col-span-6 lg:col-span-3 mb-4">
              <div className="grid grid-cols-2 items-center">
                <label
                  htmlFor="status"
                  className="block text-sm font-bold text-black   "
                >
                  Order Status:
                </label>
                <select
                  name="status"
                  value={orderData.status || ""}
                  onChange={onInputChanged}
                  id="status"
                  className="bg-gray-50 border-2 border-gray-300 text-gray-500 text-sm font-semibold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option value="pending">Pending</option>
                  <option value="completed">Completed</option>
                  <option value="processing">Processing</option>
                  <option value="cancelled">Cancelled</option>
                </select>
              </div>
            </div>
            {/* payment status */}
            <div className="col-span-6 lg:col-span-3 mb-4">
              <div className="grid grid-cols-2 items-center">
                <div className="block text-sm font-bold text-black">
                  Payment Status:
                </div>
                <div className="flex items-center">
                  <StatusLabel
                    status={orderData.payment?.status || "Unknown"}
                    replacements={{
                      processing: "Outstanding",
                    }}
                    className="px-5"
                  />
                  {["admin", "super_admin", "accounting_manager"].includes(
                    context.user.role
                  ) && (
                    <button
                      type="button"
                      onClick={() => {
                        if (orderData.payment_id) {
                          navigate(
                            `/admin/payments?id=${orderData.payment_id}`
                          );
                        }
                      }}
                      id="row-edit"
                      className="flex items-center underline rounded text-gray-700 hover:text-white p-0.5 hover:bg-gray-700 disabled:bg-gray-800 ml-1"
                      data-modal-toggle="edit-modal"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-5 h-5 icon icon-tabler icon-tabler-pencil inline"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <desc>
                          Download more icon variants from
                          https://tabler-icons.io/i/pencil
                        </desc>
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4"></path>
                        <line x1="13.5" y1="6.5" x2="17.5" y2="10.5"></line>
                      </svg>
                      <span className="hidden sm:inline">Go to Payment</span>
                    </button>
                  )}
                </div>
              </div>
            </div>

            {/* items */}
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto lg:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block w-full lg:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 lg:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50 dark:bg-gray-700">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
                          >
                            Item
                          </th>
                          {/* <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                                                        Description
                                                    </th> */}
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
                          >
                            Quantity
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
                          >
                            Price
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
                          >
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
                        {orderData.items?.map((item: any, index: number) => (
                          <tr key={index}>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900 dark:text-gray-100">
                                {item.product?.name ||
                                  item.custom?.name ||
                                  "N/A"}
                              </div>
                              {(item.product || item.custom)
                                ?.has_attributes && (
                                <div className="text-sm text-gray-500 dark:text-gray-100">
                                  {Object.keys(item.selected_attributes ?? {})
                                    .length !== 0 &&
                                    "(" +
                                      Object.values(
                                        item.selected_attributes ?? {}
                                      ).join(", ") +
                                      ")"}
                                </div>
                              )}
                              {item.type == "custom" && (
                                <div className="text-xs text-gray-500 dark:text-gray-100 mt-1">
                                  <div className="text-black underline">
                                    Description
                                  </div>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: (
                                        item?.custom?.description || "N/A"
                                      ).replace(/(?:\r\n|\r|\n)/g, "<br>"),
                                    }}
                                  ></div>
                                </div>
                              )}
                              {(item.note || item.custom?.note) && (
                                <div className="text-xs text-gray-500 dark:text-gray-100 mt-1">
                                  <div className="text-black underline">
                                    Note
                                  </div>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: (
                                        item?.note || item?.custom?.note
                                      )?.replace(/(?:\r\n|\r|\n)/g, "<br>"),
                                    }}
                                  ></div>
                                </div>
                              )}
                            </td>
                            {/* <td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="text-sm text-gray-900 dark:text-gray-100">{item.description}</div>
                                                         </td> */}
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900 dark:text-gray-100">
                                {item.quantity}
                                {/* if there's  backorder */}
                                {item.backorder_quantity > 0 && (
                                  <span className="text-xs bg-red-600 text-white rounded-full px-2 py-0.5 ml-1">
                                    {item.backorder_quantity ?? 0} Backorders
                                  </span>
                                )}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900 dark:text-gray-100">
                                <Currency />
                                {helpers.money(item.price)}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">
                                <Currency />
                                {helpers.money(item.subtotal)}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* total table */}
            {/* only admin, super_admin, accounting_manager can see this */}
            {["admin", "super_admin", "accounting_manager"].includes(
              context.user.role
            ) && (
              <div className="flex flex-col mt-4">
                <div className="-my-2 overflow-x-auto lg:-mx-6 lg:-mx-8">
                  <div className="py-2 align-middle inline-block w-full lg:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 lg:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200">
                        <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
                          {/* <tr>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">
                                  Subtotal
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-900 dark:text-gray-100 font-bold text-right">
                                  <Currency />
                                  {helpers.money(orderData.subtotal)}
                                </div>
                              </td>
                            </tr> */}
                          {/* <tr>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">Tax</div>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="text-sm text-gray-900 dark:text-gray-100 font-bold  text-right"><Currency />{orderData.tax}</div>
                                                        </td>
                                                    </tr> */}
                          <tr>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">
                                Total
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900 dark:text-gray-100 font-bold  text-right">
                                <Currency />
                                {helpers.money(orderData.total)}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Client */}
            <div className="flex flex-col mt-4">
              <label
                htmlFor="customer"
                className="block text-sm font-bold text-black   "
              >
                Client:
              </label>
              <div className="flex flex-col mt-1">
                <div className="-my-2 overflow-x-auto lg:-mx-6 lg:-mx-8">
                  <div className="py-2 align-middle inline-block w-full lg:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 lg:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200">
                        <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700 divide-none">
                          <tr>
                            <td className="px-6 py-2 whitespace-nowrap">
                              <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">
                                Name
                              </div>
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap">
                              <div className="text-sm text-gray-900 dark:text-gray-100">
                                {orderData.client?.full_name ||
                                  orderData.client_full_name ||
                                  "N/A"}
                                {orderData.is_new_client && (
                                  <span className="text-xs bg-green-600 text-white rounded-full px-2 py-0.5 ml-1">
                                    New
                                  </span>
                                )}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="px-6 py-2 whitespace-nowrap">
                              <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">
                                Contact
                              </div>
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap">
                              <div className="text-sm text-gray-900 dark:text-gray-100">
                                {orderData.client?.phone ||
                                  orderData.custom?.client?.phone ||
                                  "N/A"}
                                {" - "}
                                {orderData.client?.email ||
                                  orderData.custom?.client?.email ||
                                  "N/A"}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* sales manager */}
            <div className="flex flex-col mt-4">
              <div className="flex flex-col mt-1">
                <div className="-my-2 overflow-x-auto lg:-mx-6 lg:-mx-8">
                  <div className="py-2 align-middle inline-block w-full lg:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 lg:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200">
                        <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700 divide-none">
                          <tr>
                            <td className="px-6 py-2 whitespace-nowrap">
                              <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">
                                Sales Manager:
                              </div>
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap">
                              <div className="text-sm text-gray-900 dark:text-gray-100">
                                {orderData.sales_manager?.full_name || "N/A"}
                                {" - "}
                                {orderData.sales_manager?.phone || "N/A"}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*footer*/}
          <div className="flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">
            <button
              type="submit"
              className="bg-black hover:bg-black text-white font-semibold py-2 px-20 text-xl rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2"
            >
              Update Order
            </button>
          </div>
        </form>
      </SkyeModal>
    </>
  );
};

export default OrderModal;

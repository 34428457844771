/* eslint-disable eqeqeq */
import { Message, Phone } from "@mui/icons-material";
import { Card, IconButton } from "@mui/material";
import { useContext } from "react";
import AppContext from "../../AppContext";

type Props = {
  client: any;
  onTap?: (client: any) => void;
};
export default function SimpleClientCard({ client, onTap }: Props) {
  const context: any = useContext(AppContext);

  return (
    <Card
      sx={{ maxWidth: "100%" }}
      className="!rounded-0 !shadow-sm !border !border-gray-100 !relative"
      onClick={() => onTap && onTap(client)}
    >
      {client?.manager_id == context?.user?._id && (
        <div className="absolute top-0 right-0 bg-green-500 text-white p-0.5 px-2 text-xs">
          you
        </div>
      )}

      <div className="flex flex-row w-full gap-x-3 bg-neutral-50 hover:bg-gray-200 cursor-pointer p-3 items-center justify-between">
        <div className="font-semibold text-sm w-full">{client?.full_name}</div>

        <div className="flex flex-row gap-x-2 items-center justify-center">
          <IconButton
            color="primary"
            onClick={(e) => {
              e.stopPropagation();
              //dial phone
              window.open(`tel:${client?.phone}`, "_self");
            }}
          >
            <Phone />
          </IconButton>
          <IconButton
            color="primary"
            onClick={(e) => {
              e.stopPropagation();
              //send message
              window.open(`sms:${client?.phone}`, "_self");
            }}
          >
            <Message />
          </IconButton>
        </div>
      </div>
    </Card>
  );
}

import { useNavigate } from "react-router-dom";
import CategoryLabelCard from "../components/CategoryLabelCard";
import { useCategories } from "../hooks/useCategories";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import { useState } from "react";
import { useProducts } from "../hooks/useProducts";
import ProductCard from "../components/ProductCard";
import FreeShippingAds from "../components/FreeShippingAds";
import { Shimmer } from "react-shimmer";
import { useOrders } from "../hooks/useOrders";
import { helpers } from "../../helpers";

import Badge, { BadgeProps } from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { ShoppingCartOutlined } from "@mui/icons-material";
import { useCartContext } from "../hooks/useCart";

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 0,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

export default function AppHome() {
  const navigate = useNavigate();
  const cartContext = useCartContext();

  const [search, setSearch] = useState("");

  const openCart = () => {
    navigate("/app/cart");
  };

  const { loading: ordersLoading, ...ordersRest } = useOrders({
    initialize: true,
    params: {
      per_page: 100,
      //date range of this month only
      from: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString(),
      to: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString(),
    },
  });
  const { categories, loading: categoriesLoading } = useCategories({
    initialize: true,
  });

  const [params, setParams] = useState({
    per_page: 10,
  } as any);

  const {
    products,
    loading: productsLoading,
    ...productsRest
  } = useProducts({
    initialize: true,
    params: params,
  });

  const [sortBy, setSortBy] = useState("newest");



  return (
    <>
      <header className="z-30 fixed top-0 left-0 w-full py-4 bg-white shadow-md dark:bg-gray-800">
        <div className="container flex items-center justify-between h-full px-6 mx-auto text-purple-600 dark:text-purple-300">
          {/* <!-- Search input --> */}
          <div className="flex justify-center flex-1">
            <div className="relative w-full max-w-xl mr-2 focus-within:text-purple-500">
              <div className="absolute inset-y-0 flex items-center pl-2">
                <svg
                  className="w-4 h-4 text-gray-400"
                  area-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <input
                className="w-full pl-8 py-2 pr-2 text-lg text-gray-700 placeholder:text-gray-400 bg-white border border-gray-400 rounded-lg dark:placeholder-gray-500 dark:focus:shadow-outline-gray dark:focus:placeholder-gray-600 dark:bg-gray-700 dark:text-gray-200 focus:placeholder-gray-500 focus:bg-white focus:border-purple-300 focus:outline-none focus:shadow-outline-purple form-input"
                type="text"
                placeholder="Search for product"
                aria-label="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    navigate(`/app/products/?search=${search}`);
                  }
                }}
              />
            </div>
          </div>
          <ul className="flex items-center flex-shrink-0 space-x-6">
            <li className="relative">
              <IconButton
                aria-label="cart"
                color="primary"
                onClick={() => openCart()}
              >
                <StyledBadge
                  badgeContent={cartContext.cart.items?.length || 0}
                  color="error"
                >
                  <ShoppingCartOutlined />
                </StyledBadge>
              </IconButton>
            </li>
          </ul>
        </div>
      </header>

      <div className="mt-[80px]"></div>

      {/* stats */}
      <div className="bg-black text-white flex flex-col gap-3 items-center p-3 pb-3">
        <div className="flex items-start justify-between sm:justify-center sm:gap-x-10 w-full">
          <div className="flex flex-col gap-0 items-center">
            <div className="text-lg font-semibold text-gray-400">Sold</div>
            {ordersLoading ? (
              <Shimmer width={100} height={30} className="rounded-full" />
            ) : (
              <div className="text-lg">
                N{helpers.money(ordersRest.totalSalesAmount)}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-0 items-center">
            <div className="text-lg font-semibold text-gray-400">Pending</div>
            {ordersLoading ? (
              <Shimmer width={100} height={30} className="rounded-full" />
            ) : (
              <div className="text-lg">
                N{helpers.money(ordersRest.totalPendingAmount)}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-0 items-center">
            <div className="text-lg font-semibold text-gray-400">Paid</div>
            {ordersLoading ? (
              <Shimmer width={100} height={30} className="rounded-full" />
            ) : (
              <div className="text-lg">
                N{helpers.money(ordersRest.totalPaidAmount)}
              </div>
            )}
          </div>
        </div>
        <div className="mx-auto text-xs bg-white text-black py-0.5 px-5 rounded-full">
          Current month
        </div>
      </div>
      {/* categories */}
      <div className="overflow-x-auto w-screen py-2 pl-2 mt-4">
        {categoriesLoading ? (
          <div className="flex gap-2">
            {[1, 2, 3, 4, 5].map((item: any) => {
              return (
                <Shimmer
                  key={item + Math.random()}
                  width={130}
                  height={30}
                  className="rounded-full"
                />
              );
            })}
          </div>
        ) : (
          <Stack direction="row" spacing={1}>
            {categories.map((category: any) => {
              return (
                <CategoryLabelCard
                  key={category._id}
                  category={category}
                  onTap={(category) => {
                    navigate(`/app/products/?category=${category._id}`);
                  }}
                />
              );
            })}
          </Stack>
        )}
      </div>
      {/* sort and view all */}
      <div className="flex flex-row items-center justify-between gap-3 px-3 mt-2">
        <Select
          className="w-[200px] h-10"
          labelId="demo-select-small-label"
            // variant="standard"
          id="demo-select-small"
          value={sortBy}
          label="Newest"
          onChange={(e) => {
            const value = e.target.value as string;
            setSortBy(value);
            var order = "desc";
            if (value === "newest") {
              order = "desc";
            } else if (value === "oldest") {
              order = "asc";
            } else if (value === "price-asc") {
              order = "price-asc";
            } else if (value === "price-desc") {
              order = "price-desc";
            } else if (value === "rating") {
              order = "rating";
            }
            setParams({ ...params, order: order, page: 1 }); //reset page to 1
            productsRest.runQuery({ ...params, order: order, page: 1 }); //reset page to 1
          }}
        >
          <MenuItem value={"newest"}>Newest</MenuItem>
          <MenuItem value={"oldest"}>Oldest</MenuItem>
          <MenuItem value={"price-asc"}>Price (Low to High)</MenuItem>
          <MenuItem value={"price-desc"}>Price (High to Low)</MenuItem>
          <MenuItem value={"rating"}>Rating</MenuItem>
        </Select>
        <Button
          variant="contained"
          size="small"
          color="secondary"
          className="h-10 !px-6 !rounded-xl !capitalize"
          onClick={() => {
            navigate(`/app/products`);
          }}
        >
          View All
        </Button>
      </div>
      {/* products */}
      {/* mobile: 1 column, desktop: 3 columns  */}
      <div className="mt-3 px-4 space-y-3 sm:space-y-0 grid grid-cols-1 sm:grid-cols-3 sm:gap-3">
        {productsLoading
          ? [1, 2, 3].map((item: any) => {
              return (
                <Shimmer
                  key={item + Math.random()}
                  width={130}
                  height={120}
                  className="rounded-lg !w-full"
                />
              );
            })
          : products.map((product: any) => {
              return (
                <ProductCard
                  key={product._id}
                  product={product}
                  onTap={(product) => {
                    navigate(`/app/product/${product._id}`);
                  }}
                />
              );
            })}
      </div>
      {/* pagination */}
      {productsLoading ? (
        <div className="mt-3 px-4">
          <Shimmer width={130} height={50} className="rounded-lg !w-full" />
        </div>
      ) : (
        <div className="mt-3 px-4 flex flex-row items-center justify-between">
          <Button
            variant="contained"
            size="small"
            color={productsRest.response?.pagination?.current_page  === 1 ? "secondary" : "primary"}
            className="h-10 !px-6 !rounded-xl !capitalize !text-xl"
            onClick={() => {
              if (productsRest.response?.pagination?.current_page === 1) {
                return;
              }
              // console.log("Prev");
              setParams({ ...params, page: productsRest.response?.pagination?.current_page - 1 });
              productsRest.runQuery({ ...params, page: productsRest.response?.pagination?.current_page - 1 });
            }}
            disabled={productsRest.response?.pagination?.current_page  === 1}
          >
            « Prev
          </Button>
          <Button
            variant="contained"
            size="small"
            color={
              products.length < productsRest.response?.pagination?.per_page
                ? "secondary"
                : "primary"
            }
            className="h-10 !px-6 !rounded-xl !capitalize !text-xl"
            onClick={() => {
              if (products.length < productsRest.response?.pagination?.per_page) {
                return;
              }
              // console.log("Next");
              setParams({ ...params, page: productsRest.response?.pagination?.current_page + 1 });
              productsRest.runQuery({ ...params, page: productsRest.response?.pagination?.current_page + 1 });
            }}
            disabled={products.length < productsRest.response?.pagination?.per_page}
          >
            Next »
          </Button>
        </div>
      )}
      {/* ads */}
      <div className="mt-3 px-4">
        <FreeShippingAds />
      </div>
    </>
  );
}

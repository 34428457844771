import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import useToken from "./useToken";
import axios from "axios";
import Backend from "../presets/backend";

type UseAuthProps = {
  platform?: string;
};
const useAuth = ({platform = "web"}: UseAuthProps) => {
  const [cookies, setCookie] = useCookies(["user"]);
  const { token, login: tokenLogin, logout: tokenLogout } = useToken();
  const [user, setUser] = useState(cookies.user ?? null);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    fetchData();
    return () => {
      // Cleanup code goes here (if needed)
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    setLoading(true);
    
    try {
        const res = await axios.get(Backend.profile, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });


        if (res.data.code === "success") {
            setUser(res.data.data);
            setCookie("user", JSON.stringify(res.data.data), { path: "/" });
        } else {
            setMessage(res?.data?.message || "You need to login to access this page");
            setUser(null);
        }

        // setUser(res.data);
        setLoading(false);
    } catch (err: any) {
        console.log(err);
        setUser(null);
        setMessage(err?.response?.data?.message || err?.message || "You need to login to access this page");
        setLoading(false);
    }
}

  return {
    user,
    token,
    loading,
    message,
    tokenLogin,
    tokenLogout,
    fetchData,
    login(userData: any) {
      setCookie("user", userData, { path: "/" });
      setUser(userData);
      if (userData.token) {
        tokenLogin(userData.token);
      }
    },
    logout() {
      document.cookie.split(";").forEach((c) => {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
      setUser(null);
      tokenLogout();
    },
  };
};

export default useAuth;

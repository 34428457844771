import { useEffect, useState } from "react";
import SkyeModal from "../../components/SkyeModal";
import { toast } from "react-toastify";
import Backend from "../../presets/backend";
import axios from "axios";

type Props = {
  title?: string;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  category?: any;
  setCategory: (category: any) => void;
  onModalUpdated: (category: any) => void;
};
const CategoryModal = ({
  title = "New Category",
  showModal,
  setShowModal,
  category,
  setCategory,
  onModalUpdated,
}: Props) => {
  const [categoryData, setCategoryData] = useState(category ?? {});

  useEffect(() => {
    setCategoryData(category);
  }, [category]);

  const onInputChanged = (e: any) => {
    setCategoryData({ ...categoryData, [e.target.name]: e.target.value });
  };

  const onFormSubmit = async (e: any) => {
    e.preventDefault();
    let formData: any = { ...categoryData };

    const loading = toast.loading("Please wait...");

    try {
      if (formData._id) {
         //update
         delete formData._id;
         delete formData.created_at;
         delete formData.updated_at;
         delete formData.slug;
         delete formData.doc_id;
         delete formData.__v;
 
         const res = await axios.post(`${Backend.admin_categories}/${categoryData._id}`, formData).then((res) => res.data);
 
         if (res.code !== "success") {
           throw new Error(res.message || "An error occurred. Please try again.");
         }
 
         setCategory(res.data);
         setCategoryData(res.data);
         onModalUpdated(res.data);
         toast.success(res.message || "Category updated successfully.");
      } else {
        //add
        const res = await axios.post(Backend.admin_categories, formData).then((res) => res.data);

        if (res.code !== "success") {
          throw new Error(res.message || "An error occurred. Please try again.");
        }

        setCategory(res.data);
        setCategoryData(res.data);
        onModalUpdated(res.data);
        toast.success(res.message || "Category added successfully.");
      }

      setShowModal(false);
    } catch (error: any) {
      console.log(error);
      toast.error(
        error.response?.data?.message || error.message || "An error occurred"
      );
    } finally {
      toast.dismiss(loading);
    }
  };

  return (
    <>
      <SkyeModal
        title={categoryData._id ? "Category" : "New Category"}
        size="max-w-sm"
        flex={false}
        showModal={showModal}
        setShowModal={setShowModal}
      >
        <form
          autoComplete="off"
          className="space-y-6"
          id="category-edit-form"
          onSubmit={onFormSubmit}
          method="POST"
          encType="multipart/form-data"
        >
          {/*body*/}
          <div className="relative px-6 pt-6 pb-2 flex-auto">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Name
              </label>
              <input
                name="name"
                value={categoryData.name || ""}
                onChange={onInputChanged}
                type="text"
                id="name"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                placeholder="Name"
                required
              />
            </div>
          </div>
          {/*footer*/}
          <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
            <button
              type="submit"
              className="w-full text-white bg-black hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-gray-700 dark:focus:ring-black"
            >
              <span id="submit-text">{categoryData._id ? "Update" : "Add"}</span>
            </button>
          </div>
        </form>
      </SkyeModal>
    </>
  );
};

export default CategoryModal;

import React, { useContext, useState } from "react";
import StatusLabel from "./StatusLabel";
import CircularLoader from "./CircularLoader";
import { toast } from "react-toastify";
import { confirm } from "react-confirm-box";
import UserModal from "../pages/modal/UserModal";
import { helpers } from "../helpers";
import AppContext from "../AppContext";
import axios from "axios";
import Backend from "../presets/backend";

type Props = {
  users: any[];
  onTableUpdated: (user: any) => void;
  loading?: boolean;
  pagination?: any;
  onNextPage?: () => void;
  onPrevPage?: () => void;
};
const UsersTable = ({ users, onTableUpdated, loading = false, pagination,
  onNextPage,
  onPrevPage, }: Props) => {
  const context: any = useContext(AppContext);

  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState({});

  const onModalUpdated = (user: any) => {
    onTableUpdated(user);
  };

  const deleteUser = async (user: any) => {
    //only admin, super_admin can delete user
    if (!["admin", "super_admin"].includes(context.user.role)) {
      toast.error("You are not authorized to perform this action!");
      return;
    }

    //super admin cannot be deleted
    if (user.role === "super_admin") {
      toast.error("You cannot delete super admin");
      return;
    }

    //confirm delete
    const proceed = await confirm(
      `Are you sure you want to delete ${user.full_name}?`
    );
    if (!proceed) return;

    const loading = toast.loading("Please wait...");

    try {
      const res = await axios
        .post(`${Backend.users}/${user._id}/delete`)
        .then((res) => res.data);

      if (res.code !== "success") {
        toast.dismiss(loading);
        toast.error(res.message);
        return;
      }
      onTableUpdated(null);
      toast.success(res.message);
    } catch (error: any) {
      console.log(error);
      toast.error(error.message);
    } finally {
      toast.dismiss(loading);
    }
  };

  return (
    <>
      {/* add new */}
      <div className="text-right lg:mb-5">
        <button
          onClick={() => {
            setUser({});
            setShowModal(true);
          }}
          className="text-white bg-black py-1 px-5 rounded hover:bg-blue-600"
          type="button"
          data-modal-toggle="add-modal"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="inline icon icon-tabler icon-tabler-plus"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <desc>
              Download more icon variants from https://tabler-icons.io/i/plus
            </desc>
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <line x1="12" y1="5" x2="12" y2="19"></line>
            <line x1="5" y1="12" x2="19" y2="12"></line>
          </svg>{" "}
          New
        </button>
      </div>

      {/* <!-- New Table --> */}
      <div className="w-full overflow-hidden rounded-lg shadow-xs">
        <div className="w-full overflow-x-auto">
          <table className="w-full whitespace-no-wrap # flex flex-row flex-no-wrap lg:bg-white rounded-lg overflow-hidden lg:shadow-lg my-5">
            <thead>
              <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800 # hidden ?flex flex-col flex-no wrap lg:table-row rounded-l-lg lg:rounded-none mb-2 lg:mb-0">
                <th className="px-4 py-3">User</th>
                <th className="px-4 py-3">Email/Phone</th>
                <th className="px-4 py-3 text-center">Role</th>
                <th className="px-4 py-3">Status</th>
                <th className="px-4 py-3">Date</th>
                <th className="px-4 py-3">Action</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800 # flex-1 lg:flex-none">
              {loading && (
                <tr className="text-gray-700 dark:text-gray-400 # flex flex-col flex-no wrap lg:table-row mb-2 lg:mb-0 rounded-xl lg:rounded-none shadow lg:shadow-none hover:shadow-lg lg:hover:shadow-none border">
                  <td className="px-4 py-3 text-sm" colSpan={5}>
                    <div className="flex justify-center items-center">
                      <CircularLoader />
                      <span>Loading...</span>
                    </div>
                  </td>
                </tr>
              )}
              {users.length > 0 &&
                !loading &&
                users.map((user) => (
                  <tr
                    className="text-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer # flex flex-col flex-no wrap lg:table-row mb-2 lg:mb-0 rounded-xl lg:rounded-none shadow lg:shadow-none hover:shadow-lg lg:hover:shadow-none border"
                    onClick={() => {
                      if (user.role === "super_admin") {
                        toast.error("You cannot edit super admin");
                        return;
                      }
                      setUser(user);
                      setShowModal(true);
                    }}
                    key={user._id}
                  >
                    <td className="px-4 py-3 text-sm">
                      <div className="flex items-center text-sm">
                        {/* <!-- Avatar with inset shadow --> */}
                        <div className="relative hidden w-8 h-8 mr-3 rounded-full lg:block">
                          <img
                            className="object-cover w-full h-full rounded-full"
                            src={helpers.getFirstImage(
                              user.avatar,
                              "avatar",
                              user.full_name
                            )}
                            alt=""
                            loading="lazy"
                          />
                          <div
                            className="absolute inset-0 rounded-full shadow-inner"
                            aria-hidden="true"
                          ></div>
                        </div>
                        <div
                          onClick={() => {
                            setUser(user);
                            setShowModal(true);
                          }}
                          className="hover:text-pink-800 hover:underline hover:cursor-pointer"
                        >
                          <p className="font-semibold whitespace-nowrap text-md">
                            {user.full_name}
                          </p>
                          <p className="text-xs text-gray-600 dark:text-gray-400">
                            Addr:
                            {user.city && user.state && (
                              <>
                                {user.city}, {user.state}
                              </>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="px-4 lg:py-3 text-sm">
                      <p className="text-xs text-gray-600 dark:text-gray-400">
                        {user.email}
                      </p>
                      <p className="font-semibold">{user.phone}</p>
                    </td>
                    <td className="px-4 lg:py-3 text-xs text-right lg:text-center">
                      {helpers
                        .capitalize(
                          (user.role ?? "unknown")
                            .replace("-", " ")
                            .replace("_", " ")
                        )
                        .toUpperCase()}
                    </td>
                    <td className="px-4 pt-2 lg:py-3 text-xs text-right lg:text-left">
                      <StatusLabel status={user.status ?? "unknown"} />
                    </td>
                    <td className="px-4 lg:py-3 text-sm">
                      {new Date(user.created_at)?.toLocaleDateString()}
                    </td>
                    <td className="px-4 py-3 text-sm text-right pt-0 lg:pt-4 flex items-center justify-end">
                      {user.role !== "super_admin" && (
                        <>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              //super admin cannot be edited
                              if (user.role === "super_admin") {
                                toast.error("You cannot edit super admin");
                                return;
                              }
                              setUser(user);
                              setShowModal(true);
                            }}
                            id="row-edit"
                            className="inline bg-black rounded text-white p-2 hover:bg-gray-700 disabled:bg-gray-800"
                            data-modal-toggle="edit-modal"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-6 h-6 icon icon-tabler icon-tabler-pencil"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <desc>
                                Download more icon variants from
                                https://tabler-icons.io/i/pencil
                              </desc>
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4"></path>
                              <line
                                x1="13.5"
                                y1="6.5"
                                x2="17.5"
                                y2="10.5"
                              ></line>
                            </svg>
                          </button>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteUser(user);
                            }}
                            className="inline text-center bg-red-700 rounded text-white p-2 hover:opacity-80 ml-2"
                          >
                            <svg
                              id="icon"
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-6 h-6 icon icon-tabler icon-tabler-trash"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <desc>
                                Download more icon variants from
                                https://tabler-icons.io/i/trash
                              </desc>
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <line x1="4" y1="7" x2="20" y2="7"></line>
                              <line x1="10" y1="11" x2="10" y2="17"></line>
                              <line x1="14" y1="11" x2="14" y2="17"></line>
                              <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                              <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                            </svg>
                            <svg
                              id="loading"
                              className="hidden animate-spin h-4 w-4 icon icon-tabler text-white"
                              width="24"
                              height="24"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              {users.length === 0 && !loading && (
                <tr className="text-gray-700 dark:text-gray-400">
                  <td className="px-4 py-3 text-sm text-center" colSpan={5}>
                    <p id="row-content">No users found.</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="flex items-center justify-between md:grid px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 lg:grid-cols-9 dark:text-gray-400 dark:bg-gray-800">
          <span className="flex items-center md:col-span-3">
            {/* format Showing x - xx of xxx */}
            Showing {pagination?.from} - {pagination?.to} of {pagination?.total}
          </span>
          <span className="hidden md:block md:col-span-2"></span>
          {/* <!-- Pagination --> */}
          <span className="flex md:col-span-4 md:mt-2 lg:mt-auto lg:justify-end">
            <nav aria-label="Table navigation">
              <ul className="inline-flex items-center">
                <li>
                  <button
                    type="button"
                    onClick={onPrevPage}
                    className="px-3 py-1 rounded-md rounded-l-lg focus:outline-none focus:shadow-outline-purple bg-black text-white hover:bg-gray-200"
                    aria-label="Previous"
                  >
                    <svg
                      aria-hidden="true"
                      className="w-4 h-4 fill-current"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                        clipRule="evenodd"
                        fillRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-purple"
                  >
                    {pagination?.current_page}
                  </button>
                </li>
                <li>
                  <button
                    onClick={onNextPage}
                    type="button"
                    className="px-3 py-1 rounded-md rounded-r-lg focus:outline-none focus:shadow-outline-purple bg-black text-white hover:bg-gray-200"
                  >
                    <svg
                      className="w-4 h-4 fill-current"
                      aria-hidden="true"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                        fillRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </li>
              </ul>
            </nav>
          </span>
        </div>
      </div>

      <UserModal
        user={user}
        setUser={setUser}
        showModal={showModal}
        setShowModal={setShowModal}
        onModalUpdated={onModalUpdated}
      />
    </>
  );
};

export default UsersTable;
